import type { FC } from "react";
import { NavLink } from "react-router-dom";

interface DashCardProps {
  Text: string;
  Content: string;
  OnClick?: () => void;
}

const DashCard: FC<DashCardProps> = ({ Text, Content, OnClick }) => {
  return (
    <div
      className="row-span-1 flex justify-between px-5 border rounded-lg"
      style={{ height: "19vh" }}
      onClick={OnClick}
    >
      <div
        className="flex flex-col justify-between py-4"
        style={{ height: "17vh" }}
      >
        <span className="text-sm 2xl:text-lg font-normal text-gray-400">
          {Text}
        </span>
        <span className="text-2xl font-bold mt-1">{Content}</span>

        {/* <span className="text-xs 2xl:text-lg mt-5 font-medium text-green-500 flex items-center">
                <div className="inline-flex flex-shrink-0 items-center justify-center h-4 w-4 text-green-800 bg-green-100 rounded-full mr-1">
                  <BsArrowUp />
                </div>{" "}
                6.7% Increase
              </span> */}
      </div>
      {/* <div className="inline-flex flex-shrink-0 items-center justify-center  mr-3">
              <BsGraphUpArrow />
            </div> */}
    </div>
  );
};

interface SideBarProps {
  Link: string;
  Icon: JSX.Element;
  Content: string;
  OnClick?: () => void;
  Active: boolean;
}

const SideBarIcon: FC<SideBarProps> = ({
  Link,
  Icon,
  Content,
  OnClick,
  Active,
}) => {
  return (
    <NavLink to={Link} onClick={OnClick} className="flex flex-col items-center">
      <div
        className={
          Active
            ? "inline-flex text-base 2xl:text-xl items-center justify-center py-3 rounded-lg sidebar-icons-select"
            : "inline-flex text-base 2xl:text-xl items-center justify-center py-3 rounded-lg sidebar-icons"
        }
      >
        {Icon}
      </div>
      <span
        className={
          Active
            ? "text-xs 2xl:text-base sidebar-icons-select-text"
            : "text-xs 2xl:text-base sidebar-icons-text"
        }
      >
        {Content}
      </span>
      <span
        className={
          Active
            ? "border-b-2 mt-2 mb-4 w-3/4	"
            : "border-b mt-2 mb-4 border-gray-400 w-3/4	"
        }
      ></span>
    </NavLink>
  );
};

export { DashCard, SideBarIcon };
