import React from "react";
import {
  BsBoxArrowRight,
  BsCalendarWeek,
  BsFillGridFill,
  BsFillPeopleFill,
} from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../utils/UseContext";
import { SideBarIcon } from "../Dashboard/CustomComponent";
const logo = require("../../assets/images/logo_new.png");

interface sidebarProps {
  state: string;
  setState: Function;
}
const Sidebar: React.FC<sidebarProps> = ({ state, setState }) => {
  const { logout } = useAuth();
  React.useEffect(() => {}, [state]);
  const [isLogout, setIsLogout] = React.useState(false);
  const userInfo = JSON.parse(
    localStorage.getItem("user__details")?.toString()!
  );

  return (
    <aside className="hidden sm:flex sm:flex-col">
      {isLogout && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl rounded-tl-3xl">
              <div className="sm:flex">
                <div className="mt-1 sm:text-left w-full">
                  <p className="text-center">Are you sure you want to exit?</p>
                  <div className="flex items-center justify-evenly my-4">
                    <button
                      className="mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                      onClick={() => logout()}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => setIsLogout(false)}
                      className="mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md font-medium float-right"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <NavLink
        to="/dashboard"
        onClick={() => setState("dashboard")}
        className="inline-flex items-center flex-col justify-center h-20 w-20 2xl:w-24 sidebar-bg-dark"
      >
        <img src={logo} alt="" className="p-2"></img>
        {/* <span className="border-b mt-2  mb-4 border-gray-400 w-full"></span> */}
      </NavLink>

      <div className="flex-grow flex flex-col justify-between text-gray-500 sidebar-bg-dark">
        <nav className="flex flex-col mx-1">
          <SideBarIcon
            Link="/dashboard"
            Icon={<BsFillGridFill />}
            Content="Dashboard"
            OnClick={() => setState("dashboard")}
            Active={state === "dashboard" ? true : false}
          />

          {userInfo.master_id === null && (
            <SideBarIcon
              Link="/user_management"
              Icon={<BsFillPeopleFill />}
              Content="User"
              OnClick={() => setState("user_management")}
              Active={state === "user_management" ? true : false}
            />
          )}
          <SideBarIcon
            Link="/interviewer_management"
            Icon={<FaUserTie />}
            Content="Interviewer"
            OnClick={() => setState("interviewer_management")}
            Active={state === "interviewer_management" ? true : false}
          />
          <SideBarIcon
            Link="/report_list"
            Icon={<BsCalendarWeek />}
            Content="Reports"
            OnClick={() => setState("report")}
            Active={state === "report" ? true : false}
          />
        </nav>
        <div className="inline-flex items-center justify-center h-20 w-20 border-t border-white">
          <button className="p-3 text-xl 2xl:text-2xl hover:text-gray-400 focus:text-gray-400">
            <span className="sr-only">Logout</span>
            <div
              onClick={() => setIsLogout(true)}
              className={
                "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
              }
            >
              <span className="sr-only">Logout</span>
              <BsBoxArrowRight />
            </div>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
