import { useFormik } from "formik";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Style } from "../../utils/Style";
// import StarRatings from "react-star-ratings";
import { BsCardImage } from "react-icons/bs";
import orgApi from "../../api/org_api";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import intApi from "../../api/int_api";
import StarRatingComp from "../../component/StarRatingCom";
import ReactHlsPlayer from "react-hls-player";
import S from "../../Services/Services";

const InterviewerAddTechFeedbackNamIT: React.FC = () => {
  const [candidateProxy, setCandidateProxy] = React.useState(false);
  const [candidateNoShow, setCandidateNoShow] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [fileError, setfileError] = React.useState("");
  const [scheduleDetail, setScheduleDetail] = React.useState({} as any);
  const { schedule_id } = useParams();
  const [jdDetail, setJdDetail] = React.useState({} as any);
  const [primary, setPrimary] = React.useState([]);
  const [secondary, setSecondary] = React.useState([]);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [isCompleted, setCompleted] = React.useState(false);
  const [starState, setStarState] = React.useState(false);
  const [mandatory, setMandatory] = React.useState([]);
  const [hasImage, setHasImage] = React.useState(false);

  function isCurrentDateTimeLessThanFixedDateTimePlusOneDay(
    fixedDateTimeString: string
  ) {
    const currentDateTime = new Date();
    const fixedDateTime = new Date(fixedDateTimeString);
    const fixedDateTimePlusOneDay = new Date(fixedDateTime);
    fixedDateTimePlusOneDay.setDate(fixedDateTime.getDate() + 1);
    return currentDateTime < fixedDateTimePlusOneDay;
  }

  const formik = useFormik({
    initialValues: {
      interview_mode: "",
      interview_level: "",
      communication_level: "1",
      junior_level: "",
      mid_level: "",
      senior_level: "",
      lead: "",
      architect_level: "",
      technical_observation: "",
      analytical_problemsolving: "",
      attitude: "",
      clarity_answer: "",
      recommended_for: "",
      final_recommendation: "",
      other_comments: "",
      recommended: "no",
    },
    validationSchema: Yup.object({}),
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (values) {
        var profilePic: any;
        setfileError("");
        if (candidateNoShow || candidateProxy) {
          if (selectedImage === null) {
            if (candidateProxy && !candidateNoShow) {
              setfileError("Please attach the candidate profile");
              toast("Please attach the candidate profile");
              return;
            }
          } else {
            if (!hasImage) {
              profilePic = await getProfileURL();
              if (!profilePic) {
                setfileError("Please attach the candidate profile");
                toast("Please attach the candidate profile");
                return;
              }
            } else {
              profilePic = selectedImage;
            }
          }
          setisDisabled(true);
          const req = {
            schedule_id: schedule_id,
            interview_status: "Incomplete",
            candidate_no_show: candidateNoShow,
            proxy: candidateProxy,
            url:
              profilePic !== undefined &&
              profilePic !== "" &&
              profilePic !== null
                ? profilePic
                : "",
            primary_skill: [],
            secondary_skill: [],
            mandatory_skill: [],
          };
          console.log("req--", req);
          if (scheduleDetail.feedback_status === "Completed") {
            const res = await intApi.editFeedback(
              req,
              scheduleDetail.feedback[0].id
            );
            if (res.data.success) {
              toast("Feedback Updated Successfully");
              setTimeout(() => {
                // setisDisabled(false);
                window.location.href = "https://screenit.io";
              }, 1000);
            } else {
              setisDisabled(false);
              toast(res.data.msg);
            }
          } else {
            const res = await intApi.addFeedback(req, schedule_id);
            if (res.data.success) {
              toast("Feedback Submitted Successfully");
              setTimeout(() => {
                // setisDisabled(false);
                window.location.href = "https://screenit.io";
              }, 1000);
            } else {
              setisDisabled(false);
              toast(res.data.msg);
            }
          }
        } else {
          let pskill: any = [];
          let sskill: any = [];
          let psdata = await validateSkills();
          let flag = 0;
          for (let index = 0; index < psdata.primary.length; index++) {
            if (psdata.primary[index]["rating_err"]) {
              flag++;
              break;
            }
          }
          if (flag != 0) {
            return;
          }
          let flag1 = 0;
          for (let index = 0; index < psdata.secondary.length; index++) {
            if (psdata.secondary[index]["rating_err"]) {
              flag1++;
              break;
            }
          }
          if (flag1 != 0) {
            return;
          }
          pskill = psdata.primary;
          sskill = psdata.secondary;
          if (selectedImage === null) {
            setfileError("Please attach the candidate profile");
            toast("Please attach the candidate profile");
            return;
          } else {
            if (!hasImage) {
              profilePic = await getProfileURL();
              if (!profilePic) {
                setfileError("Please attach the candidate profile");
                toast("Please attach the candidate profile");
                return;
              }
            } else {
              profilePic = selectedImage;
            }
          }
          setisDisabled(true);
          const req = {
            schedule_id: schedule_id,
            interview_status: "Completed",
            mode: values.interview_mode,
            level: values.interview_level,
            junior: values.junior_level,
            mid: values.mid_level,
            senior: values.senior_level,
            lead: values.lead,
            architect: values.architect_level,
            communication_skill: Number(values.communication_level),
            observation: values.technical_observation,
            problem_solving_cmd: values.analytical_problemsolving,
            attitude_confidence_cmd: values.attitude,
            inter_personal_skill_cmd: values.clarity_answer,
            recommended_status: values.final_recommendation,
            comments: values.other_comments,
            eligible_for_this_position:
              values.recommended === "yes" ? true : false,
            professionalism_cmd: values.recommended_for,
            primary_skill: pskill,
            secondary_skill: sskill,
            mandatory_skill: mandatory,
            url:
              profilePic !== undefined &&
              profilePic !== "" &&
              profilePic !== null
                ? profilePic
                : "",
          };
          console.log(req);
          if (scheduleDetail.feedback_status === "Completed") {
            const res = await intApi.editFeedback(
              req,
              scheduleDetail.feedback[0].id
            );
            if (res.data.success) {
              toast("Feedback Updated Successfully");
              setTimeout(() => {
                // setisDisabled(false);
                window.location.href = "https://screenit.io";
              }, 1000);
            } else {
              setisDisabled(false);
              toast(res.data.msg);
            }
          } else {
            const res = await intApi.addFeedback(req, schedule_id);
            if (res.data.success) {
              toast("Feedback Submitted Successfully");
              setTimeout(() => {
                // setisDisabled(false);
                window.location.href = "https://screenit.io";
              }, 1000);
            } else {
              setisDisabled(false);
              toast(res.data.msg);
            }
          }
        }
      }
    },
  });

  React.useEffect(() => {
    setTimeout(() => {
      setStarState(true);
      console.log(starState);
    }, 500);
  }, []);

  React.useEffect(() => {
    fetchScheduleDetails();
  }, []);

  const fetchScheduleDetails = async () => {
    if (schedule_id) {
      const res = await orgApi.getScheduleData(schedule_id);
      if (res.data.success) {
        var pSkills = await fetchPostDetails(res.data.schedule.post_id);
        var mSkills = await fetchMandatorySkills();
        setCompleted(false);
        if (res.data.schedule.feedback_status === "Completed") {
          var enable = isCurrentDateTimeLessThanFixedDateTimePlusOneDay(
            res.data.schedule.feedback[0].created_on
          );
          setCompleted(!enable);
        }
        if (res.data.schedule.feedback_status === "Completed") {
          var d = res.data.schedule.feedback[0];
          setCandidateNoShow(d["candidate_no_show"]);
          setCandidateProxy(d["proxy"]);
          formik.setFieldValue("interview_mode", d["mode"]);
          formik.setFieldValue("interview_level", d["level"]);
          formik.setFieldValue(
            "communication_level",
            d["communication_skill"].toString()
          );
          formik.setFieldValue("junior_level", d["junior"]);
          formik.setFieldValue("mid_level", d["mid"]);
          formik.setFieldValue("senior_level", d["senior"]);
          formik.setFieldValue("lead", d["lead"]);
          formik.setFieldValue("architect_level", d["architect"]);
          formik.setFieldValue("technical_observation", d["observation"]);
          formik.setFieldValue(
            "analytical_problemsolving",
            d["problem_solving_cmd"]
          );
          formik.setFieldValue("attitude", d["attitude_confidence_cmd"]);
          formik.setFieldValue("clarity_answer", d["inter_personal_skill_cmd"]);
          formik.setFieldValue("final_recommendation", d["recommended_status"]);
          formik.setFieldValue("other_comments", d["comments"]);
          formik.setFieldValue(
            "recommended",
            d["eligible_for_this_position"] ? "yes" : "no"
          );
          formik.setFieldValue("recommended_for", d["professionalism_cmd"]);
          setSelectedImage(d["url"]);
          setHasImage(true)
          setPrimary(pSkills["pskill"]);
          setSecondary(pSkills["sskil"]);
          setMandatory(mSkills);
          for (let index = 0; index < d["fp_skill"].length; index++) {
            const element = d["fp_skill"][index];

            if (pSkills["pskill"].length !== 0) {
              setPrimary((prevItems: any) =>
                prevItems.map((item: any) =>
                  item["skill_id"] === element.skill_id
                    ? { ...item, skill_rating: element.skill_rating }
                    : item
                )
              );
              setPrimary((prevItems: any) =>
                prevItems.map((item: any) =>
                  item["skill_id"] === element.skill_id
                    ? { ...item, experience: element.experience }
                    : item
                )
              );
            }
          }
          for (let index1 = 0; index1 < d["fs_skill"].length; index1++) {
            const element1 = d["fs_skill"][index1];
            if (pSkills["sskil"].length !== 0) {
              setSecondary((prevItems: any) =>
                prevItems.map((item: any) =>
                  item["skill_id"] === element1.skill_id
                    ? { ...item, skill_rating: element1.skill_rating }
                    : item
                )
              );
              setSecondary((prevItems: any) =>
                prevItems.map((item: any) =>
                  item["skill_id"] === element1.skill_id
                    ? { ...item, experience: element1.experience }
                    : item
                )
              );
            }
          }
          for (let index2 = 0; index2 < d["fm_skill"].length; index2++) {
            const element2 = d["fm_skill"][index2];
            if (mSkills.length !== 0) {
              setMandatory((prevItems: any) =>
                prevItems.map((item: any) =>
                  item["skill_id"] === element2.skill_id
                    ? { ...item, skill_rating: element2.skill_rating }
                    : item
                )
              );
              setMandatory((prevItems: any) =>
                prevItems.map((item: any) =>
                  item["skill_id"] === element2.skill_id
                    ? { ...item, remarks: element2.remarks }
                    : item
                )
              );
            }
          }
        } else {
          if (res.data.schedule["url"]) {
            setSelectedImage(res.data.schedule["url"]);
            setHasImage(true)
          }
        }
        setScheduleDetail(res.data.schedule);
      } else {
        toast(res.data.msg);
      }
    } else {
      toast("Schedule id is undefined");
    }
  };

  const fetchPostDetails = async (postId: any) => {
    const res = await orgApi.getSingleJdDetail(postId);
    if (res.data.success) {
      setJdDetail(res.data.post);
      var pskill: any = [];
      var sskil: any = [];
      var sdata: any = res.data.post.template;
      sdata["t_p_skill"].map((e: any) => {
        pskill.push({
          schedule_id: schedule_id,
          skill_name: e.skill.name,
          skill_id: e.skill.id,
          weightage: e.weightage,
          skill_rating: 0,
          rating_err: false,
          experience: "",
        });
      });
      sdata["t_s_skill"].map((e: any) => {
        sskil.push({
          schedule_id: schedule_id,
          skill_name: e.skill.name,
          skill_id: e.skill.id,
          weightage: e.weightage,
          skill_rating: 0,
          rating_err: false,
          experience: "",
        });
      });
      setPrimary(pskill);
      setSecondary(sskil);
      return { pskill: pskill, sskil: sskil };
    } else {
      toast(res.data.msg);
      return { pskill: [], sskil: [] };
    }
  };

  const fetchMandatorySkills = async () => {
    const res = await orgApi.getMandatorySkills();
    if (res.data.success) {
      var mskill: any = [];
      res.data.man_skill.map((e: any) => {
        mskill.push({
          schedule_id: schedule_id,
          skill_name: e.skill.name,
          skill_id: e.skill.id,
          skill_rating: 0,
          remarks: "",
        });
      });
      setMandatory(mskill);
      return mskill;
    } else {
      return [];
    }
  };

  const validateSkills = async () => {
    var psk = [] as any;
    var ssk = [] as any;
    psk = primary;
    ssk = secondary;
    for (let ps = 0; ps < psk.length; ps++) {
      if (psk[ps].skill_rating == 0) {
        setPrimary((prevItems: any) =>
          prevItems.map((item: any) =>
            item.skill_id === psk[ps].skill_id
              ? { ...item, rating_err: true }
              : item
          )
        );
        psk[ps].rating_err = true;
      } else {
        setPrimary((prevItems: any) =>
          prevItems.map((item: any) =>
            item.skill_id === psk[ps].skill_id
              ? { ...item, rating_err: false }
              : item
          )
        );
        psk[ps].rating_err = false;
      }
    }
    for (let ss = 0; ss < ssk.length; ss++) {
      if (ssk[ss].skill_rating == 0) {
        setSecondary((prevItems: any) =>
          prevItems.map((item: any) =>
            item.skill_id === ssk[ss].skill_id
              ? { ...item, rating_err: true }
              : item
          )
        );
        ssk[ss].rating_err = true;
      } else {
        setSecondary((prevItems: any) =>
          prevItems.map((item: any) =>
            item.skill_id === ssk[ss].skill_id
              ? { ...item, rating_err: false }
              : item
          )
        );
        ssk[ss].rating_err = false;
      }
    }
    return { primary: psk, secondary: ssk };
  };

  const getProfileURL = async () => {
    let f;
    await urlToBase64(selectedImage!).then(async (base64Url: any) => {
      var file = await dataURLtoFile(
        base64Url,
        Math.random().toString(36).substring(6) + "_name.png"
      );
      const formData = new FormData();
      formData.append("pic", file!);
      const res1 = await orgApi.getUrl(formData);
      if (res1.data.success) {
        f = res1.data.url;
        // return res1.data.url;
      } else {
        f = "";
        // return "";
      }
    });
    return f;
  };

  const dataURLtoFile = async (dataurl: any, filename: any) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  async function urlToBase64(url: string) {
    try {
      console.log("enters urlToBase64");
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
      });
    } catch (error) {
      console.error("Error converting URL to base64:", error);
      return null;
    }
  }

  const handleImageChange = (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        if (file.size <= 2 * 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            setSelectedImage(e.target.result);
            setHasImage(false)
            setfileError("");
          };
          reader.readAsDataURL(file);
        } else {
          setfileError(
            "File size exceeds the limit. Please select a smaller file."
          );
        }
      } else {
        setfileError("Please select an image file.");
      }
    }
  };

  const getDate = (dateStr: any) => {
    const formattedDate = format(dateStr, "dd MMM yyyy");
    return formattedDate;
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div className="flex justify-center bg-gray-100 w-full min-h-screen">
        {Object.keys(scheduleDetail).length !== 0 &&
          Object.keys(jdDetail).length !== 0 &&
          (!isCompleted ? (
            <div className="w-3/5 bg-white pl-5 pr-5">
              <div className="flex justify-center items-center max-h-screen overflow-hidden border-b">
                <div className="py-6">
                  <h1 className="text-sm font-semibold">
                    Interactive Technical Assessment
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-3 bg-primary border-primary p-4 gap-3 rounded-tl-lg rounded-br-lg">
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light ">
                    Candidate Name
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {scheduleDetail["candidate"]["first_name"]}{" "}
                    {scheduleDetail["candidate"]["last_name"] === null
                      ? ""
                      : scheduleDetail["candidate"]["last_name"]}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Panelist's Name
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {scheduleDetail.interviewer.candidate.first_name}{" "}
                    {scheduleDetail.interviewer.candidate.last_name === null
                      ? ""
                      : scheduleDetail.interviewer.candidate.last_name}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Recruiter's Name:
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {jdDetail.org.first_name} {jdDetail.org.last_name}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">Role</h1>
                  <h1 className="text-white text-xs font-bold">
                    {jdDetail.job_title}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Date(DD MMM YYYY):
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {getDate(scheduleDetail.utc_starttime)}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Name of the Client:
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {jdDetail.client_name !== "" ? jdDetail.client_name : "NA"}
                  </h1>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="flex justify-between items-center my-3 grid grid-cols-2 gap-3">
                  <div className="flex justify-start items-center gap-4">
                    <h1 className="text-xs font-normal text-gray-500">
                      Candidate No Show
                    </h1>
                    <div className="flex justify-left gap-2">
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateNoShow"
                          value="yes"
                          checked={candidateNoShow}
                          onChange={() => setCandidateNoShow(true)}
                        />
                        <h1 className="text-black text-xs font-bold">Yes</h1>
                      </div>
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateNoShow"
                          value="no"
                          checked={!candidateNoShow}
                          onChange={() => setCandidateNoShow(false)}
                        />
                        <h1 className="text-black text-xs font-bold">No</h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-center gap-4">
                    <h1 className="text-xs font-normal text-gray-500">
                      Candidate Proxy
                    </h1>
                    <div className="flex justify-left gap-2">
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateProxy"
                          value="yes"
                          checked={candidateProxy}
                          onChange={() => setCandidateProxy(true)}
                        />
                        <h1 className="text-black text-xs font-bold">Yes</h1>
                      </div>
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateProxy"
                          value="no"
                          checked={!candidateProxy}
                          onChange={() => setCandidateProxy(false)}
                        />
                        <h1 className="text-black text-xs font-bold">No</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center my-3 grid grid-cols-2 gap-3">
                  <div className="flex justify-start items-center gap-4">
                    <h1 className="text-xs font-normal text-gray-500">
                      Mode of Interview
                    </h1>
                    <div className="flex justify-left gap-2">
                      <select
                        id="interview_mode"
                        name="interview_mode"
                        value={formik.values.interview_mode}
                        onBlur={formik.handleBlur}
                        onChange={(v: any) => {
                          formik.setFieldValue(
                            "interview_mode",
                            v.target.value
                          );
                        }}
                        className={
                          formik.touched.interview_mode &&
                          formik.errors.interview_mode
                            ? Style.iError
                            : Style.iInput
                        }
                      >
                        <option value="" disabled>
                          <h1 className="text-sm font-thin text-slate-800">
                            Interview
                          </h1>
                        </option>
                        <option value="Zoom">
                          <h1 className="text-sm font-thin text-slate-800">
                            Zoom
                          </h1>
                        </option>
                        <option value="WebEx">
                          <h1 className="text-sm font-thin text-slate-800">
                            WebEx
                          </h1>
                        </option>
                        <option value="In Person">
                          <h1 className="text-sm font-thin text-slate-800">
                            In Person
                          </h1>
                        </option>
                        <option value="Hangout Teams">
                          <h1 className="text-sm font-thin text-slate-800">
                            Hangout Teams
                          </h1>
                        </option>
                        <option value="Skype">
                          <h1 className="text-sm font-thin text-slate-800">
                            Skype
                          </h1>
                        </option>
                        <option value="Microsoft Teams">
                          <h1 className="text-sm font-thin text-slate-800">
                            Microsoft Teams
                          </h1>
                        </option>
                        <option value="ScreenIt">
                          <h1 className="text-sm font-thin text-slate-800">
                            ScreenIt
                          </h1>
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    className={
                      candidateNoShow || candidateProxy
                        ? "disabled-div flex justify-start items-center gap-4"
                        : "flex justify-start items-center gap-4"
                    }
                  >
                    <h1 className="text-xs font-normal text-gray-500">
                      Level of Interview
                    </h1>
                    <div className="flex justify-left gap-2">
                      <select
                        id="interview_level"
                        name="interview_level"
                        value={formik.values.interview_level}
                        onBlur={formik.handleBlur}
                        onChange={(v: any) => {
                          formik.setFieldValue(
                            "interview_level",
                            v.target.value
                          );
                        }}
                        className={
                          formik.touched.interview_level &&
                          formik.errors.interview_level
                            ? Style.iError
                            : Style.iInput
                        }
                      >
                        <option value="" disabled>
                          <h1 className="text-sm font-thin text-slate-800">
                            Select Level
                          </h1>
                        </option>
                        <option value="Junior">
                          <h1 className="text-sm font-thin text-slate-800">
                            Junior
                          </h1>
                        </option>
                        <option value="Mid">
                          <h1 className="text-sm font-thin text-slate-800">
                            Mid
                          </h1>
                        </option>
                        <option value="Senior">
                          <h1 className="text-sm font-thin text-slate-800">
                            Senior
                          </h1>
                        </option>
                        <option value="Lead">
                          <h1 className="text-sm font-thin text-slate-800">
                            Lead
                          </h1>
                        </option>
                        <option value="Architect">
                          <h1 className="text-sm font-thin text-slate-800">
                            Architect
                          </h1>
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    candidateNoShow || candidateProxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <p className="font-semibold text-xs underline text-gray-900">
                    Scoring Definitions
                  </p>
                  <div className="grid grid-cols-11 flex items-center justify-start ml-6 text-xs font-semibold text-gray-600 gap-1">
                    <p className="col-span-3 w-full">1 to 1.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Lacks overall competency, needs improvement (Rejected)
                    </p>
                    <p className="col-span-3 w-full">2 to 2.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Limited competency, scalable in shorter term (Junior
                      level)
                    </p>

                    <p className="col-span-3 w-full">3 to 3.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Appreciable and broader competency, rapidly scalable (Mid
                      / Senior level)
                    </p>

                    <p className="col-span-3 w-full">4 to 4.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Demonstrated competency, consistently adaptive (Lead /
                      Architect level)
                    </p>

                    <p className="col-span-3 w-full">5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Proficient competency, and progressive (SME level)
                    </p>
                  </div>
                </div>
                <div
                  className={
                    candidateNoShow || candidateProxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Technical Evaluation
                    </h1>
                    <h1 className="text-xs font-normal text-gray-400">
                      Please evaluate the candidate on the following on a scale
                      of 1 to 5 (1 Lowest, 5 Highest)
                    </h1>
                  </div>
                  <table className="table-fixed w-full mt-2">
                    <thead className="text-sm">
                      <tr>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Required Technical Skills
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Rating
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Comments
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-400 ">
                      {primary.map((e: any) => {
                        return (
                          <tr className="bg-gray-50 border-b-2 border-white ">
                            <td>
                              <p className="px-2 py-1 text-xs text-gray-500">
                                {e.skill_name}
                              </p>
                              <p className="px-2 text-xs font-thin text-primary mb-2">
                                Weightage: {e.weightage}%
                              </p>
                            </td>
                            <td>
                              <p className="p-2">
                                <div className="flex flex-row gap-3 items-center justify-start ">
                                  <StarRatingComp
                                    stars={e.skill_rating}
                                    callBack={(rate: any) => {
                                      setPrimary((prevItems: any) =>
                                        prevItems.map((item: any) =>
                                          item.skill_id === e.skill_id
                                            ? { ...item, skill_rating: rate }
                                            : item
                                        )
                                      );
                                      setPrimary((prevItems: any) =>
                                        prevItems.map((item: any) =>
                                          item.skill_id === e.skill_id &&
                                          rate > 0
                                            ? { ...item, rating_err: false }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  <p className="text-xs text-gray-500">
                                    {e.skill_rating}
                                  </p>
                                </div>
                              </p>
                              {e.rating_err && (
                                <div className="error">{"Select Rating*"}</div>
                              )}
                            </td>
                            <td>
                              <textarea
                                placeholder="Enter your Comments"
                                className={Style.iInput}
                                rows={2}
                                cols={40}
                                id="experience"
                                name="experience"
                                value={e.experience}
                                onChange={(v: any) => {
                                  setPrimary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? {
                                            ...item,
                                            experience: v.target.value,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <table className="table-fixed w-full mt-2">
                    <thead className="text-sm">
                      <tr>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Preferred Technical Skills
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Rating
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Comments
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-400 ">
                      {secondary.map((e: any) => {
                        return (
                          <tr className="bg-gray-50 border-b-2 border-white ">
                            <td>
                              <p className="px-2 py-1 text-xs text-gray-500">
                                {e.skill_name}
                              </p>
                              <p className="px-2 text-xs font-thin text-primary mb-2">
                                Weightage: {e.weightage}%
                              </p>
                            </td>
                            <td>
                              <p className="p-2">
                                <div className="flex flex-row gap-3 items-center justify-start ">
                                  <StarRatingComp
                                    stars={e.skill_rating}
                                    callBack={(rate: any) => {
                                      setSecondary((prevItems: any) =>
                                        prevItems.map((item: any) =>
                                          item.skill_id === e.skill_id
                                            ? { ...item, skill_rating: rate }
                                            : item
                                        )
                                      );
                                      setSecondary((prevItems: any) =>
                                        prevItems.map((item: any) =>
                                          item.skill_id === e.skill_id &&
                                          rate > 0
                                            ? { ...item, rating_err: false }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  <p className="text-xs text-gray-500">
                                    {e.skill_rating}
                                  </p>
                                </div>
                              </p>
                              {e.rating_err && (
                                <div className="error">{"Select Rating*"}</div>
                              )}
                            </td>
                            <td>
                              <textarea
                                placeholder="Enter your Comments"
                                className={Style.iInput}
                                rows={2}
                                cols={40}
                                id="experience"
                                name="experience"
                                value={e.experience}
                                onChange={(v: any) => {
                                  setSecondary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? {
                                            ...item,
                                            experience: v.target.value,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <table className="table-fixed w-full mt-2">
                    <thead className="text-sm">
                      <tr>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Mandatory Skills
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Rating
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Comments
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-400 ">
                      {mandatory.map((e: any) => {
                        return (
                          <tr className="bg-gray-50 border-b-2 border-white ">
                            <td>
                              <p className="px-2 py-1 text-xs text-gray-500">
                                {e.skill_name}
                              </p>
                            </td>
                            <td>
                              <p className="p-2">
                                <div className="flex flex-row gap-3 items-center justify-start ">
                                  <StarRatingComp
                                    stars={e.skill_rating}
                                    callBack={(rate: any) => {
                                      setMandatory((prevItems: any) =>
                                        prevItems.map((item: any) =>
                                          item.skill_id === e.skill_id
                                            ? { ...item, skill_rating: rate }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  <p className="text-xs text-gray-500">
                                    {e.skill_rating}
                                  </p>
                                </div>
                              </p>
                            </td>
                            <td>
                              <textarea
                                placeholder="Enter your Comments"
                                className={Style.iInput}
                                rows={2}
                                cols={40}
                                id="remarks"
                                name="remarks"
                                value={e.remarks}
                                onChange={(v: any) => {
                                  setMandatory((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? {
                                            ...item,
                                            remarks: v.target.value,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div
                  className={
                    candidateNoShow || candidateProxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Level of Experience
                    </h1>
                    <table className="table-fixed w-full mt-2">
                      <thead className="text-sm">
                        <tr>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Category
                            </p>
                          </th>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Comments
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-400 ">
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Junior</p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter  Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="junior_level"
                              name="junior_level"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.junior_level}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Mid</p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter  Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="mid_level"
                              name="mid_level"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.mid_level}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Senior</p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="senior_level"
                              name="senior_level"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.senior_level}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Lead</p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="lead"
                              name="lead"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lead}
                            />
                          </td>
                        </tr>

                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Architect
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter  Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="architect_level"
                              name="architect_level"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.architect_level}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    candidateNoShow || candidateProxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <p className="font-semibold text-xs underline text-gray-900">
                    Communication level of expertise (Defined standard, level of
                    oral articulation)
                  </p>
                  <div className="flex flex-col items-start justify-start ml-6 text-xs font-semibold text-gray-600 gap-2">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"1"}
                        checked={
                          formik.values.communication_level === "1"
                            ? true
                            : false
                        }
                        name="communication_level"
                        id="communication_level"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        1.Speaks with limited fluency, irregular grammar and
                        carries a distinct accent
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"2"}
                        checked={
                          formik.values.communication_level === "2"
                            ? true
                            : false
                        }
                        name="communication_level"
                        id="communication_level"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        2.Speaks with comfortable fluency, agreeable grammar,
                        has noticeable accent
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"3"}
                        checked={
                          formik.values.communication_level === "3"
                            ? true
                            : false
                        }
                        name="communication_level"
                        id="communication_level"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        3.Speaks with acceptable finesse/ accurate grammar and
                        has no accent
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"4"}
                        checked={
                          formik.values.communication_level === "4"
                            ? true
                            : false
                        }
                        name="communication_level"
                        id="communication_level"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        4.Speaks with polish / finesse. Demonstrates confidence
                        in presenting the credentials with ease
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    candidateNoShow || candidateProxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Observations
                    </h1>
                    <table className="table-fixed w-full mt-2">
                      <thead className="text-sm">
                        <tr>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Communication Level
                            </p>
                          </th>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              (Ref one of the four listed above)
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-400 ">
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Technical Observation
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="technical_observation"
                              name="technical_observation"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.technical_observation}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Analytical and problem-solving Skills
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="analytical_problemsolving"
                              name="analytical_problemsolving"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.analytical_problemsolving}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Attitude / Aptitude demonstrated
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="attitude"
                              name="attitude"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.attitude}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Clarity of the answers provided
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="clarity_answer"
                              name="clarity_answer"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.clarity_answer}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    candidateNoShow || candidateProxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Final Recommendation
                    </h1>
                    <table className="table-fixed w-full mt-2">
                      <tbody className="text-gray-400 ">
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Must Hire/Hire/Hold/Do not Hire
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="final_recommendation"
                              name="final_recommendation"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.final_recommendation}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Junior/ Mid/ Senior/ Lead/ Architect
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="recommended_for"
                              name="recommended_for"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.recommended_for}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Other Comments, if any:
                            </p>
                          </td>

                          <td>
                            <textarea
                              placeholder="Enter Comments"
                              className={Style.iInput}
                              rows={2}
                              cols={40}
                              id="other_comments"
                              name="other_comments"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.other_comments}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    candidateNoShow || candidateProxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Recommended?
                    </h1>
                    <div className="flex justify-left gap-2">
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          value="yes"
                          name="recommended"
                          id="recommended"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={
                            formik.values.recommended === "yes" ? true : false
                          }
                        />
                        <h1 className="text-black text-xs font-bold">Yes</h1>
                      </div>
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          value="no"
                          name="recommended"
                          id="recommended"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={
                            formik.values.recommended === "no" ? true : false
                          }
                        />
                        <h1 className="text-black text-xs font-bold">No</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label className={Style.label}>
                    Candidate Profile Picture
                    {!candidateNoShow && <span title="required">*</span>}
                  </label>

                  <label className="cursor-pointer p-2">
                    <div className="flex items-center w-1/2 border-primary rounded h-16 gap-1">
                      {selectedImage && (
                        <div className="w-16 h-16 mr-4 p-0.5 flex justify-center items-center border-r overflow-hidden">
                          <img
                            className="h-full w-full object-cover"
                            src={selectedImage}
                            alt="Avatar Upload"
                          ></img>
                        </div>
                      )}
                      {!selectedImage && (
                        <div className="w-16 h-16 flex justify-center items-center border-r  overflow-hidden ">
                          <BsCardImage className="text-primary" size={24} />
                        </div>
                      )}
                      <div className="flex flex-col justify-start gap-1">
                        <div className="font-semibold text-xs text-gray-700">
                          Upload Image{" "}
                        </div>
                        <div className="font-thin text-xs text-gray-400">
                          Images with format of jpeg, png & jpg are acceptable
                        </div>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleImageChange}
                      ></input>
                    </div>
                  </label>

                  {fileError !== "" ? (
                    <div className="error">{fileError}</div>
                  ) : null}
                </div>
                <div className="py-10 flex justify-end">
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className={
                      !isDisabled
                        ? "flex items-center px-3 py-2 text-white text-sm font-semibold button-primary border rounded-md mb-3"
                        : "flex items-center px-3 py-2 text-white text-sm font-semibold button-primary-tint border rounded-md mb-3"
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
              {scheduleDetail["status"] === "Completed" && (
                  <div className="flex justify-start mt-2">
                    <div className="my-2 flex flex-col gap-2">
                      {S.getFileExtension(scheduleDetail.video_url) != "mp4" ? (
                        <ReactHlsPlayer
                          src={scheduleDetail.video_url}
                          autoPlay={false}
                          controls={true}
                          width="750"
                          height="500"
                        />
                      ) : (
                        <video
                          src={scheduleDetail.video_url}
                          autoPlay={false}
                          controls={true}
                          width="750"
                          height="500"
                        />
                      )}
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <div className="w-3/5 bg-white pl-5 pr-5 py-10">
              <div className="flex flex-row justify-center font-bold text-sm text-black">
                Feedback has been submitted Already
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default InterviewerAddTechFeedbackNamIT;
