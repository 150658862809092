import React from "react";
import { Style } from "../../utils/Style";
import { useFormik } from "formik";
import * as Yup from "yup";
import StarRatings from "react-star-ratings";
import orgApi from "../../api/org_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import intApi from "../../api/int_api";
import { BsCardImage } from "react-icons/bs";
import ReactHlsPlayer from "react-hls-player";
import S from "../../Services/Services";

const InterviewerAddTechFeedback: React.FC = () => {
  const [candidateProxy, setCandidateProxy] = React.useState(false);
  const [candidateNoShow, setCandidateNoShow] = React.useState(false);
  const [intStatus, setIntStatus] = React.useState("Completed");
  const [windowViolation, setWindowViolation] = React.useState(false);
  const [jdDetail, setJdDetail] = React.useState({} as any);
  const [scheduleDetail, setScheduleDetail] = React.useState({} as any);
  const [cancelReasons, setCancelReason] = React.useState([]);
  const [primary, setPrimary] = React.useState([]);
  const [secondary, setSecondary] = React.useState([]);
  const { schedule_id } = useParams();
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [fileError, setfileError] = React.useState("");
  const [isDisabled, setisDisabled] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  React.useEffect(() => {
    fetchScheduleDetails();
    fetchCancelReason();
  }, []);

  const getDate = (dateStr: any) => {
    const formattedDate = format(dateStr, "dd MMM yyyy");
    return formattedDate;
  };
  const getTime = (dateStr: any) => {
    const formattedDate = format(dateStr, "hh:mm a");
    return formattedDate;
  };
  const fetchScheduleDetails = async () => {
    if (schedule_id) {
      const res = await orgApi.getScheduleData(schedule_id);
      if (res.data.success) {
        setScheduleDetail(res.data.schedule);
        fetchPostDetails(res.data.schedule.post_id);
        if (res.data.schedule["url"]) {
          setSelectedImage(res.data.schedule["url"]);
          setHasImage(true);
        }
      } else {
        toast(res.data.msg);
      }
    } else {
      toast("Schedule id is undefined");
    }
  };
  const fetchCancelReason = async () => {
    const res = await orgApi.getCancelReason();
    res.data.success ? setCancelReason(res.data.reason) : toast(res.data.msg);
  };
  const fetchPostDetails = async (postId: any) => {
    const res = await orgApi.getSingleJdDetail(postId);
    if (res.data.success) {
      setJdDetail(res.data.post);
      var pskill: any = [];
      var sskil: any = [];
      var sdata: any = res.data.post.template;
      sdata["t_p_skill"].map((e: any) => {
        pskill.push({
          schedule_id: schedule_id,
          skill_name: e.skill.name,
          skill_id: e.skill.id,
          weightage: e.weightage,
          skill_rating: 0,
          experience: "",
          program_concept: "",
          logics: "",
          development_tools: "",
          skill_type: "technical",
          rate: false,
          concept: false,
          logic: false,
          tool: false,
          exp: false,
        });
      });
      sdata["t_s_skill"].map((e: any) => {
        sskil.push({
          schedule_id: schedule_id,
          skill_name: e.skill.name,
          skill_id: e.skill.id,
          weightage: e.weightage,
          skill_rating: 0,
          experience: "",
          program_concept: "",
          logics: "",
          development_tools: "",
          skill_type: "technical",
          rate: false,
          concept: false,
          logic: false,
          tool: false,
          exp: false,
        });
      });
      setPrimary(pskill);
      setSecondary(sskil);
    } else {
      toast(res.data.msg);
    }
  };
  const formik = useFormik({
    initialValues: {
      interview_status: "Completed",
      other_comments: "",
      interviewercomments: "",
      sit_score: 0,
      recommended: "",
      reason: "",
      incomplete_comments: "",
      commun_rating: 0,
      attention_rating: 0,
      integrity_rating: 0,
      cooperation_rating: 0,
      stress_rating: 0,
      learning_rating: 0,
    },
    validationSchema: Yup.object({
      interview_status:
        !candidateProxy && !candidateNoShow
          ? Yup.string().required("Select Status")
          : Yup.string(),
      other_comments:
        !candidateProxy &&
        !candidateNoShow &&
        intStatus === "Completed" &&
        windowViolation
          ? Yup.string().required("Please enter your comments")
          : Yup.string(),
      interviewercomments:
        !candidateProxy && !candidateNoShow && intStatus === "Completed"
          ? Yup.string().required("Please enter your comments")
          : Yup.string(),
      sit_score:
        !candidateProxy && !candidateNoShow && intStatus === "Completed"
          ? Yup.number()
              .required("Please enter SIT Score")
              .min(1, "Please enter the SIT score")
          : Yup.string(),
      reason:
        intStatus !== "Completed"
          ? Yup.string().required("Select reason")
          : Yup.string(),
      incomplete_comments:
        intStatus !== "Completed"
          ? Yup.string().required("Enter the comments")
          : Yup.string(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (values) {
        var profilePic;
        setfileError("");

        if (candidateNoShow || candidateProxy) {
          if (selectedImage === null) {
            if (candidateProxy && !candidateNoShow) {
              setfileError("Please attach the candidate profile");
              toast("Please attach the candidate profile");
              return;
            } else if (
              values.interview_status === "Completed" &&
              !candidateNoShow
            ) {
              setfileError("Please attach the candidate profile");
              toast("Please attach the candidate profile");
              return;
            }
          } else {
            if (!hasImage) {
              profilePic = await getProfileURL();
              if (!profilePic) {
                setfileError("Please attach the candidate profile");
                toast("Please attach the candidate profile");
                return;
              }
            } else {
              profilePic = selectedImage;
            }
          }
          setisDisabled(true);
          const req = {
            schedule_id: schedule_id,
            interview_status: "Incomplete",
            candidate_no_show: candidateNoShow,
            proxy: candidateProxy,
            url:
              profilePic !== undefined &&
              profilePic !== "" &&
              profilePic !== null
                ? profilePic
                : "",
            primary_skill: [],
            secondary_skill: [],
            mandatory_skill: [],
          };
          const res = await intApi.addFeedback(req, schedule_id);
          if (res.data.success) {
            toast("Feedback Submitted Successfully");
            setTimeout(() => {
              // setisDisabled(false);
              window.location.href = "https://screenit.io";
            }, 1000);
          } else {
            setisDisabled(false);
            toast(res.data.msg);
          }
        } else {
          var pskill: any = [];
          var sskill: any = [];
          if (values.interview_status === "Completed") {
            var psdata = await validateSkills();
            var flag = 0;
            for (let index = 0; index < psdata.primary.length; index++) {
              if (
                psdata.primary[index]["concept"] ||
                psdata.primary[index]["logic"] ||
                psdata.primary[index]["tool"] ||
                psdata.primary[index]["exp"] ||
                psdata.primary[index]["rate"]
              ) {
                flag++;
                break;
              }
            }
            if (flag != 0) {
              return;
            }
            var flag1 = 0;
            for (let index = 0; index < psdata.secondary.length; index++) {
              if (
                psdata.secondary[index]["concept"] ||
                psdata.secondary[index]["logic"] ||
                psdata.secondary[index]["tool"] ||
                psdata.secondary[index]["exp"] ||
                psdata.secondary[index]["rate"]
              ) {
                flag1++;
                break;
              }
            }
            if (flag1 !== 0) {
              return;
            }
            pskill = primary;
            sskill = secondary;
          } else {
            pskill = [];
            sskill = [];
          }
          if (selectedImage === null) {
            if (candidateProxy && !candidateNoShow) {
              setfileError("Please attach the candidate profile");
              toast("Please attach the candidate profile");
              return;
            } else if (
              values.interview_status === "Completed" &&
              !candidateNoShow
            ) {
              setfileError("Please attach the candidate profile");
              toast("Please attach the candidate profile");
              return;
            }
          } else {
            if (!hasImage) {
              profilePic = await getProfileURL();
              if (!profilePic) {
                setfileError("Please attach the candidate profile");
                toast("Please attach the candidate profile");
                return;
              }
            } else {
              profilePic = selectedImage;
            }
          }
          setisDisabled(true);
          const req = {
            schedule_id: schedule_id,
            interview_status: values.interview_status,
            comments: values.interviewercomments,
            window_violoation_cmt: values.other_comments,
            sit_score: values.sit_score,
            recommended_status: values.recommended,
            attention: values.attention_rating,
            communication_skill: values.commun_rating,
            co_operation: values.cooperation_rating,
            incomplete_reason_cmt: values.incomplete_comments,
            incomplete_reason_id: values.reason === "" ? null : values.reason,
            intrigity: values.integrity_rating,
            learning_ability: values.learning_rating,
            stress_tolerance: values.stress_rating,
            primary_skill: pskill,
            secondary_skill: sskill,
            mandatory_skill: [],
            url:
              profilePic !== undefined &&
              profilePic !== "" &&
              profilePic !== null
                ? profilePic
                : "",
          };
          const res = await intApi.addFeedback(req, schedule_id);
          if (res.data.success) {
            toast("Feedback Submitted Successfully");
            setTimeout(() => {
              // setisDisabled(false);
              window.location.href = "https://screenit.io";
            }, 1000);
          } else {
            setisDisabled(false);
            toast(res.data.msg);
          }
        }
      }
    },
  });

  const getProfileURL = async () => {
    console.log("selectedImage getProfileURL - ", selectedImage);
    // var file = dataURLtoFile(
    //   selectedImage,
    //   Math.random().toString(36).substring(6) + "_name.png"
    // );
    // const formData = new FormData();
    // formData.append("pic", file!);
    // const res1 = await orgApi.getUrl(formData);
    // if (res1.data.success) {
    //   return res1.data.url;
    // } else {
    //   return "";
    // }
    let f;
    await urlToBase64(selectedImage!).then(async (base64Url: any) => {
      var file = await dataURLtoFile(
        base64Url,
        Math.random().toString(36).substring(6) + "_name.png"
      );
      const formData = new FormData();
      formData.append("pic", file!);
      const res1 = await orgApi.getUrl(formData);
      if (res1.data.success) {
        f = res1.data.url;
        // return res1.data.url;
      } else {
        f = "";
        // return "";
      }
    });
    return f;
  };

  async function urlToBase64(url: string) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
      });
    } catch (error) {
      console.error("Error converting URL to base64:", error);
      return null;
    }
  }

  const validateSkills = async () => {
    var psk = [] as any;
    var ssk = [] as any;
    psk = primary;
    ssk = secondary;
    for (let index = 0; index < psk.length; index++) {
      psk[index].program_concept === ""
        ? (psk[index].concept = true)
        : (psk[index].concept = false);
      psk[index].logics === ""
        ? (psk[index].logic = true)
        : (psk[index].logic = false);
      psk[index].development_tools === ""
        ? (psk[index].tool = true)
        : (psk[index].tool = false);
      psk[index].experience === ""
        ? (psk[index].exp = true)
        : (psk[index].exp = false);
      psk[index].skill_rating === 0
        ? (psk[index].rate = true)
        : (psk[index].rate = false);
    }
    for (let index = 0; index < ssk.length; index++) {
      ssk[index].program_concept === ""
        ? (ssk[index].concept = true)
        : (ssk[index].concept = false);
      ssk[index].logics === ""
        ? (ssk[index].logic = true)
        : (ssk[index].logic = false);
      ssk[index].development_tools === ""
        ? (ssk[index].tool = true)
        : (ssk[index].tool = false);
      ssk[index].experience === ""
        ? (ssk[index].exp = true)
        : (ssk[index].exp = false);
      ssk[index].skill_rating === 0
        ? (ssk[index].rate = true)
        : (ssk[index].rate = false);
    }
    setPrimary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.skill_rating === 0
          ? { ...item, rate: true }
          : { ...item, rate: false }
      )
    );
    setPrimary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.program_concept === ""
          ? { ...item, concept: true }
          : { ...item, concept: false }
      )
    );
    setPrimary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.logics === ""
          ? { ...item, logic: true }
          : { ...item, logic: false }
      )
    );
    setPrimary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.development_tools === ""
          ? { ...item, tool: true }
          : { ...item, tool: false }
      )
    );
    setPrimary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.experience === ""
          ? { ...item, exp: true }
          : { ...item, exp: false }
      )
    );
    setSecondary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.skill_rating === 0
          ? { ...item, rate: true }
          : { ...item, rate: false }
      )
    );
    setSecondary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.program_concept === ""
          ? { ...item, concept: true }
          : { ...item, concept: false }
      )
    );
    setSecondary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.logics === ""
          ? { ...item, logic: true }
          : { ...item, logic: false }
      )
    );
    setSecondary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.development_tools === ""
          ? { ...item, tool: true }
          : { ...item, tool: false }
      )
    );
    setSecondary((prevItems: any) =>
      prevItems.map((item: any) =>
        item.experience === ""
          ? { ...item, exp: true }
          : { ...item, exp: false }
      )
    );
    return { primary: psk, secondary: ssk };
  };
  const changeScore = (n: number) => {
    formik.values.sit_score = Number(n);
    if (formik.values.sit_score < 5) {
      formik.setFieldValue("recommended", "Rejected");
    } else if (formik.values.sit_score < 7) {
      formik.setFieldValue("recommended", "Near Fit");
    } else if (formik.values.sit_score < 9) {
      formik.setFieldValue("recommended", "Hire");
    } else if (formik.values.sit_score > 8) {
      formik.setFieldValue("recommended", "Strong Hire");
    }
  };

  const handleImageChange = (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        if (file.size <= 2 * 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            setSelectedImage(e.target.result);
            setHasImage(false);
            setfileError("");
          };
          reader.readAsDataURL(file);
        } else {
          setfileError(
            "File size exceeds the limit. Please select a smaller file."
          );
        }
      } else {
        setfileError("Please select an image file.");
      }
    }
  };
  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>

      {Object.keys(scheduleDetail).length !== 0 &&
        Object.keys(jdDetail).length !== 0 && (
          <div className="flex justify-center bg-gray-100 w-full min-h-screen">
            {scheduleDetail["feedback_status"] !== "Completed" ? (
              <div className="w-3/5 bg-white pl-5 pr-5">
                <div className="flex justify-center items-center max-h-screen overflow-hidden border-b">
                  <div className="pt-10">
                    <h1 className="text-md font-semibold">
                      Interview Evaluation Sheet
                    </h1>
                  </div>
                </div>
                <div className="flex justify-left mb-2">
                  <div className="pt-3 text-md ">
                    <h1 className="text-slate-800 text-sm font-semibold ">
                      {jdDetail.job_title}
                    </h1>
                  </div>
                </div>
                <div className="grid grid-cols-3 bg-primary border-primary p-4 gap-3 rounded-tl-lg rounded-br-lg">
                  <div className="">
                    <h1 className="text-white text-xs font-light ">
                      Candidate Name
                    </h1>
                    <h1 className="text-white text-xs font-bold">
                      {scheduleDetail["candidate"]["first_name"]}{" "}
                      {scheduleDetail["candidate"]["last_name"] == null
                        ? ""
                        : scheduleDetail["candidate"]["last_name"]}
                    </h1>
                  </div>
                  <div className="">
                    <h1 className="text-white text-xs font-light">
                      Candidate Email Id
                    </h1>
                    <h1 className="text-white text-xs font-bold">
                      {scheduleDetail.candidate.email}
                    </h1>
                  </div>
                  <div className="">
                    <h1 className="text-white text-xs font-light">
                      Interviewer Name
                    </h1>
                    <h1 className="text-white text-xs font-bold">
                      {scheduleDetail.interviewer.candidate.first_name}{" "}
                      {scheduleDetail.interviewer.candidate.last_name == null
                        ? ""
                        : scheduleDetail.interviewer.candidate.last_name}
                    </h1>
                  </div>
                  <div className="">
                    <h1 className="text-white text-xs font-light">
                      Interview Date
                    </h1>
                    <h1 className="text-white text-xs font-bold">
                      {getDate(scheduleDetail.utc_starttime)}
                    </h1>
                  </div>
                  <div className="">
                    <h1 className="text-white text-xs font-light">
                      Interview Time
                    </h1>
                    <h1 className="text-white text-xs font-bold">
                      {getTime(scheduleDetail.utc_starttime)} to{" "}
                      {getTime(scheduleDetail.utc_endtime)}
                    </h1>
                  </div>
                  <div className="">
                    <h1 className="text-white text-xs font-light">
                      Mode of Interview
                    </h1>
                    <h1 className="text-white text-xs font-bold">{"Video"}</h1>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="flex justify-between items-center my-3 grid grid-cols-3">
                    <div className="flex justify-start items-center gap-4">
                      <h1 className="text-xs font-normal text-gray-500">
                        Candidate No Show
                      </h1>
                      <div className="flex justify-left gap-2">
                        <div className="flex justify-start item-center gap-1">
                          <input
                            type="radio"
                            name="candidateNoShow"
                            value="yes"
                            checked={candidateNoShow}
                            onChange={() => setCandidateNoShow(true)}
                          />
                          <h1 className="text-black text-xs font-bold">Yes</h1>
                        </div>
                        <div className="flex justify-start item-center gap-1">
                          <input
                            type="radio"
                            name="candidateNoShow"
                            value="no"
                            checked={!candidateNoShow}
                            onChange={() => setCandidateNoShow(false)}
                          />
                          <h1 className="text-black text-xs font-bold">No</h1>
                        </div>
                      </div>
                    </div>
                    <div></div>
                    <div className="flex justify-start items-center gap-4">
                      <h1 className="text-xs font-normal text-gray-500">
                        Candidate Proxy
                      </h1>
                      <div className="flex justify-left gap-2">
                        <div className="flex justify-start item-center gap-1">
                          <input
                            type="radio"
                            name="candidateProxy"
                            value="yes"
                            checked={candidateProxy}
                            onChange={() => setCandidateProxy(true)}
                          />
                          <h1 className="text-black text-xs font-bold">Yes</h1>
                        </div>
                        <div className="flex justify-start item-center gap-1">
                          <input
                            type="radio"
                            name="candidateProxy"
                            value="no"
                            checked={!candidateProxy}
                            onChange={() => setCandidateProxy(false)}
                          />
                          <h1 className="text-black text-xs font-bold">No</h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      candidateNoShow || candidateProxy
                        ? "disabled-div grid grid-cols-3 w-1/2 my-3 items-center"
                        : "grid grid-cols-3 w-1/2 my-3 items-center"
                    }
                  >
                    <h1 className="text-xs font-normal text-gray-500 col-span-1">
                      Interview Status
                    </h1>
                    <div className="flex justify-left col-span-2">
                      <select
                        id="interview_status"
                        name="interview_status"
                        value={formik.values.interview_status}
                        onBlur={formik.handleBlur}
                        onChange={(v: any) => {
                          formik.setFieldValue(
                            "interview_status",
                            v.target.value
                          );
                          setIntStatus(v.target.value);
                        }}
                        className={
                          formik.touched.interview_status &&
                          formik.errors.interview_status
                            ? Style.iError
                            : Style.iInput
                        }
                      >
                        <option value="" disabled>
                          <h1 className="text-sm font-thin text-slate-800">
                            Status
                          </h1>
                        </option>
                        <option value="Completed">
                          <h1 className="text-sm font-thin text-slate-800">
                            Completed
                          </h1>
                        </option>
                        <option value="Incomplete">
                          <h1 className="text-sm font-thin text-slate-800">
                            Incomplete
                          </h1>
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className={Style.label}>
                      Candidate Profile Picture<span title="required">*</span>
                    </label>

                    <label className="cursor-pointer p-2">
                      <div className="flex items-center w-1/2 border-primary rounded h-16 gap-1">
                        {selectedImage && (
                          <div className="w-16 h-16 mr-4 p-0.5 flex justify-center items-center border-r overflow-hidden">
                            <img
                              className="h-full w-full object-cover"
                              src={selectedImage}
                              alt="Avatar Upload"
                            ></img>
                          </div>
                        )}
                        {!selectedImage && (
                          <div className="w-16 h-16 flex justify-center items-center border-r  overflow-hidden ">
                            <BsCardImage className="text-primary" size={24} />
                          </div>
                        )}
                        <div className="flex flex-col justify-start gap-1">
                          <div className="font-semibold text-xs text-gray-700">
                            Upload Image{" "}
                          </div>
                          <div className="font-thin text-xs text-gray-400">
                            Images with format of jpeg, png & jpg are acceptable
                          </div>
                        </div>
                        <input
                          type="file"
                          className="hidden"
                          onChange={handleImageChange}
                        ></input>
                      </div>
                    </label>

                    {fileError !== "" ? (
                      <div className="error">{fileError}</div>
                    ) : null}
                  </div>
                  {formik.values.interview_status === "Completed" && (
                    <div
                      className={
                        candidateNoShow || candidateProxy ? "disabled-div" : ""
                      }
                    >
                      <div className="pt-5">
                        <h1 className="text-sm font-semibold text-gray-800 mb-2">
                          Technical Evaluation
                        </h1>
                        <h1 className="text-xs font-normal text-gray-400">
                          Please evaluate the candidate on the following on a
                          scale of 1 to 5 (1 Lowest, 5 Highest)
                        </h1>
                      </div>
                      <div className="flex justify-left pt-5 pb-3">
                        <h1 className="text-xs font-semibold text-gray-800">
                          Skill Details
                        </h1>
                      </div>
                      {primary.map((e: any) => (
                        <div className="bg-gray-100 rounded p-4 mb-1">
                          <div className="flex items-center justify-between">
                            <h1 className="text-sm font-semibold text-gray-700 mb-2">
                              Skill Name: {e.skill_name}
                            </h1>
                            <p className="text-xs font-semibold text-primary mb-2">
                              Weightage: {e.weightage}%
                            </p>
                          </div>
                          <div className="grid grid-cols-3 gap-3">
                            <div>
                              <p className="text-xs text-gray-500 font-normal">
                                Rating
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-1">
                                <StarRatings
                                  rating={e.skill_rating}
                                  changeRating={(rate: number) => {
                                    setPrimary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.skill_id === e.skill_id
                                          ? { ...item, skill_rating: rate }
                                          : item
                                      )
                                    );
                                    setPrimary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.skill_rating === 0 &&
                                        item.skill_id === e.skill_id
                                          ? { ...item, rate: true }
                                          : { ...item, rate: false }
                                      )
                                    );
                                  }}
                                  numberOfStars={5}
                                  name="rating"
                                  starRatedColor="orange"
                                  starHoverColor="orange"
                                  starDimension="18px"
                                  starSpacing="3px"
                                />
                              </p>
                              {e.rate && (
                                <>
                                  <div className="error">
                                    {"Select Rating*"}
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <h1 className="text-xs text-gray-500 font-normal">
                                Experience
                              </h1>
                              <div className=" ">
                                <select
                                  id="experience"
                                  name="experience"
                                  value={e.experience}
                                  onChange={(v: any) => {
                                    setPrimary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.skill_id === e.skill_id
                                          ? {
                                              ...item,
                                              experience: v.target.value,
                                            }
                                          : item
                                      )
                                    );
                                    setPrimary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.experience === "" &&
                                        item.skill_id === e.skill_id
                                          ? { ...item, exp: true }
                                          : { ...item, exp: false }
                                      )
                                    );
                                  }}
                                  className={Style.iInput}
                                >
                                  <option value="" disabled>
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Select Experience
                                    </h1>
                                  </option>
                                  <option value="Good">
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Good
                                    </h1>
                                  </option>
                                  <option value="Poor">
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Poor
                                    </h1>
                                  </option>
                                  <option value="Intermediate">
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Intermediate
                                    </h1>
                                  </option>
                                </select>
                                {e.exp && (
                                  <>
                                    <div className="error">
                                      {"Select your Experience*"}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div></div>
                            <div>
                              <p className="text-xs text-gray-500 font-normal">
                                Technical Concepts
                              </p>
                              <textarea
                                name="Enter your Comments"
                                // value={e.program_concept}
                                onChange={(v: any) => {
                                  setPrimary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? {
                                            ...item,
                                            program_concept: v.target.value,
                                          }
                                        : item
                                    )
                                  );
                                  setPrimary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.program_concept === "" &&
                                      item.skill_id === e.skill_id
                                        ? { ...item, concept: true }
                                        : { ...item, concept: false }
                                    )
                                  );
                                }}
                                className={
                                  e.concept ? Style.iError : Style.iInput
                                }
                                rows={3}
                                cols={40}
                              />
                              {e.concept && (
                                <>
                                  <div className="error">
                                    {"Enter your Comments*"}
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <h1 className="text-xs text-gray-500 font-normal">
                                Development Tools
                              </h1>
                              <textarea
                                name="Enter your Comments"
                                // value={e.development_tools}
                                onChange={(v: any) => {
                                  setPrimary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? {
                                            ...item,
                                            development_tools: v.target.value,
                                          }
                                        : item
                                    )
                                  );
                                  setPrimary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.development_tools === "" &&
                                      item.skill_id === e.skill_id
                                        ? { ...item, tool: true }
                                        : { ...item, tool: false }
                                    )
                                  );
                                }}
                                className={e.tool ? Style.iError : Style.iInput}
                                rows={3}
                                cols={40}
                              />
                              {e.tool && (
                                <>
                                  <div className="error">
                                    {"Enter your Comments*"}
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <p className="text-xs text-gray-500 font-normal">
                                Logic
                              </p>

                              <textarea
                                name="Enter your Comments"
                                // value={e.logics}
                                onChange={(v: any) => {
                                  setPrimary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? { ...item, logics: v.target.value }
                                        : item
                                    )
                                  );
                                  setPrimary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.logics === "" &&
                                      item.skill_id === e.skill_id
                                        ? { ...item, logic: true }
                                        : { ...item, logic: false }
                                    )
                                  );
                                }}
                                className={
                                  e.logic ? Style.iError : Style.iInput
                                }
                                rows={3}
                                cols={40}
                              />
                              {e.logic && (
                                <>
                                  <div className="error">
                                    {"Enter your Comments*"}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      {secondary.map((e: any) => (
                        <div className="bg-gray-100 rounded p-4 mb-1">
                          <div className="flex items-center justify-between">
                            <h1 className="text-sm font-semibold text-gray-700 mb-2">
                              Skill Name: {e.skill_name}
                            </h1>
                            <p className="text-xs font-semibold text-primary mb-2">
                              Weightage: {e.weightage}%
                            </p>
                          </div>
                          <div className="grid grid-cols-3 gap-3">
                            <div>
                              <p className="text-xs text-gray-500 font-normal">
                                Rating
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-1">
                                <StarRatings
                                  rating={e.skill_rating}
                                  changeRating={(rate: number) => {
                                    setSecondary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.skill_id === e.skill_id
                                          ? { ...item, skill_rating: rate }
                                          : item
                                      )
                                    );
                                    setSecondary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.skill_rating === 0 &&
                                        item.skill_id === e.skill_id
                                          ? { ...item, rate: true }
                                          : { ...item, rate: false }
                                      )
                                    );
                                  }}
                                  numberOfStars={5}
                                  name="rating"
                                  starRatedColor="orange"
                                  starHoverColor="orange"
                                  starDimension="18px"
                                  starSpacing="3px"
                                />
                              </p>
                              {e.rate && (
                                <>
                                  <div className="error">
                                    {"Select Rating*"}
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <h1 className="text-xs text-gray-500 font-normal">
                                Experience
                              </h1>
                              <div className=" ">
                                <select
                                  id="experience"
                                  name="experience"
                                  value={e.experience}
                                  onChange={(v: any) => {
                                    setSecondary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.skill_id === e.skill_id
                                          ? {
                                              ...item,
                                              experience: v.target.value,
                                            }
                                          : item
                                      )
                                    );
                                    setSecondary((prevItems: any) =>
                                      prevItems.map((item: any) =>
                                        item.experience === "" &&
                                        item.skill_id === e.skill_id
                                          ? { ...item, exp: true }
                                          : { ...item, exp: false }
                                      )
                                    );
                                  }}
                                  className={Style.iInput}
                                >
                                  <option value="" disabled>
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Select Experience
                                    </h1>
                                  </option>
                                  <option value="Good">
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Good
                                    </h1>
                                  </option>
                                  <option value="Poor">
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Poor
                                    </h1>
                                  </option>
                                  <option value="Intermediate">
                                    <h1 className="text-sm text-slate-800 font-thin">
                                      Intermediate
                                    </h1>
                                  </option>
                                </select>
                                {e.exp && (
                                  <>
                                    <div className="error">
                                      {"Select your Experience*"}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div></div>
                            <div>
                              <p className="text-xs text-gray-500 font-normal">
                                Technical Concepts
                              </p>

                              <textarea
                                name="Enter your Comments"
                                // value={e.program_concept}
                                onChange={(v: any) => {
                                  setSecondary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? {
                                            ...item,
                                            program_concept: v.target.value,
                                          }
                                        : item
                                    )
                                  );
                                  setSecondary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.program_concept === "" &&
                                      item.skill_id === e.skill_id
                                        ? { ...item, concept: true }
                                        : { ...item, concept: false }
                                    )
                                  );
                                }}
                                className={
                                  e.concept ? Style.iError : Style.iInput
                                }
                                rows={3}
                                cols={40}
                              />
                              {e.concept && (
                                <>
                                  <div className="error">
                                    {"Enter your Comments*"}
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <h1 className="text-xs text-gray-500 font-normal">
                                Development Tools
                              </h1>

                              <textarea
                                name="Enter your Comments"
                                // value={e.development_tools}
                                onChange={(v: any) => {
                                  setSecondary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? {
                                            ...item,
                                            development_tools: v.target.value,
                                          }
                                        : item
                                    )
                                  );
                                  setSecondary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.development_tools === "" &&
                                      item.skill_id === e.skill_id
                                        ? { ...item, tool: true }
                                        : { ...item, tool: false }
                                    )
                                  );
                                }}
                                className={e.tool ? Style.iError : Style.iInput}
                                rows={3}
                                cols={40}
                              />
                              {e.tool && (
                                <>
                                  <div className="error">
                                    {"Enter your Comments*"}
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <p className="text-xs text-gray-500 font-normal">
                                Logic
                              </p>

                              <textarea
                                name="Enter your Comments"
                                // value={e.logics}
                                onChange={(v: any) => {
                                  setSecondary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.skill_id === e.skill_id
                                        ? { ...item, logics: v.target.value }
                                        : item
                                    )
                                  );
                                  setSecondary((prevItems: any) =>
                                    prevItems.map((item: any) =>
                                      item.logics === "" &&
                                      item.skill_id === e.skill_id
                                        ? { ...item, logic: true }
                                        : { ...item, logic: false }
                                    )
                                  );
                                }}
                                className={
                                  e.logic ? Style.iError : Style.iInput
                                }
                                rows={3}
                                cols={40}
                              />
                              {e.logic && (
                                <>
                                  <div className="error">
                                    {"Enter your Comments*"}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="pt-5">
                        <table className="table-fixed w-full">
                          <thead className="text-sm">
                            <tr>
                              <th className="w-1/2">
                                <p className="p-2 flex justify-start">
                                  Non-Technical Evaluation
                                </p>
                              </th>
                              <th className="w-1/2">
                                <p className="p-2 flex justify-start">Rating</p>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-400 text-xs">
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2">Communication Level</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={formik.values.commun_rating}
                                    changeRating={(rate: number) => {
                                      formik.setFieldValue(
                                        "commun_rating",
                                        rate
                                      );
                                    }}
                                    numberOfStars={5}
                                    name="commun_rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2">Attention Details</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={formik.values.attention_rating}
                                    changeRating={(rate: number) => {
                                      formik.setFieldValue(
                                        "attention_rating",
                                        rate
                                      );
                                    }}
                                    numberOfStars={5}
                                    name="attention_rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2">Integrity</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={formik.values.integrity_rating}
                                    changeRating={(rate: number) => {
                                      formik.setFieldValue(
                                        "integrity_rating",
                                        rate
                                      );
                                    }}
                                    numberOfStars={5}
                                    name="integrity_rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2">Cooperation</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={formik.values.cooperation_rating}
                                    changeRating={(rate: number) => {
                                      formik.setFieldValue(
                                        "cooperation_rating",
                                        rate
                                      );
                                    }}
                                    numberOfStars={5}
                                    name="cooperation_rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2">Stress Tolerance</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={formik.values.stress_rating}
                                    changeRating={(rate: number) => {
                                      formik.setFieldValue(
                                        "stress_rating",
                                        rate
                                      );
                                    }}
                                    numberOfStars={5}
                                    name="stress_rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2">Learning Ability</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={formik.values.learning_rating}
                                    changeRating={(rate: number) => {
                                      formik.setFieldValue(
                                        "learning_rating",
                                        rate
                                      );
                                    }}
                                    numberOfStars={5}
                                    name="learning_rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="pt-5">
                        <h1 className="text-sm font-semibold text-gray-900">
                          Others
                        </h1>
                      </div>
                      <div className="grid grid-cols-4 py-3">
                        <h1 className=" col-span-1 text-xs font-normal text-gray-500 ">
                          Window Violation
                        </h1>
                        <h1 className=" col-span-1 text-xs font-normal text-gray-500 ">
                          <div
                            className="w-12 h-6 flex items-center justify-between bg-gray-200 rounded-full p-1 cursor-pointer"
                            onClick={() => setWindowViolation(!windowViolation)}
                          >
                            <p
                              className={
                                "text-gray-700 font-semibold" +
                                (!windowViolation
                                  ? " transform translate-x-7  "
                                  : " ")
                              }
                              style={{ fontSize: "8px" }}
                            >
                              {windowViolation ? "Yes" : "No"}
                            </p>
                            <div
                              className={
                                "h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
                                (windowViolation
                                  ? "  bg-blue-500 "
                                  : " bg-gray-500 transform -translate-x-5")
                              }
                            ></div>
                          </div>
                        </h1>
                        <div className="col-span-2 w-full">
                          <textarea
                            // value={formik.values.other_comments}
                            placeholder="Enter your Comments"
                            id="other_comments"
                            name="other_comments"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.other_comments &&
                              formik.errors.other_comments
                                ? Style.iError
                                : Style.iInput
                            }
                            rows={2}
                            cols={40}
                          />
                          {formik.touched.other_comments &&
                          formik.errors.other_comments ? (
                            <div className="error">
                              {formik.errors.other_comments}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="pt-5">
                        <h1 className="text-sm font-semibold text-gray-900">
                          Final Recommendation
                        </h1>
                      </div>
                      <div className="grid grid-cols-2 gap-2 py-3">
                        <h1 className="text-xs font-normal text-gray-500">
                          Interviewer Comments
                        </h1>
                        <div>
                          <textarea
                            // value={formik.values.interviewercomments}
                            placeholder="Enter your Comments"
                            id="interviewercomments"
                            name="interviewercomments"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.interviewercomments &&
                              formik.errors.interviewercomments
                                ? Style.iError
                                : Style.iInput
                            }
                            rows={2}
                            cols={40}
                          />
                          {formik.touched.interviewercomments &&
                          formik.errors.interviewercomments ? (
                            <div className="error">
                              {formik.errors.interviewercomments}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="grid grid-cols-2">
                        <h1 className="text-xs font-normal text-gray-500">
                          Screenit Score
                        </h1>
                        <div>
                          <div className="flex flex-row gap-1">
                            {numbers.map((number) => (
                              <button
                                type="button"
                                onClick={() => {
                                  formik.setFieldValue("sit_score", number);
                                  changeScore(number);
                                }}
                                className={
                                  number <= formik.values.sit_score
                                    ? "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold button-primary border rounded-md mb-3 "
                                    : "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold bg-gray-200 border rounded-md mb-3 "
                                }
                              >
                                {number}
                              </button>
                            ))}
                          </div>
                          {formik.touched.sit_score &&
                          formik.errors.sit_score ? (
                            <div className="error">
                              {formik.errors.sit_score}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="grid grid-cols-2">
                        <h1 className="text-xs font-normal text-gray-500">
                          Recommended
                        </h1>
                        <label className="text-xs font-bold text-blue-500">
                          {formik.values.recommended}
                        </label>
                      </div>
                    </div>
                  )}
                  {formik.values.interview_status !== "Completed" && (
                    <div
                      className={
                        candidateNoShow || candidateProxy ? "disabled-div" : ""
                      }
                    >
                      <div className="grid grid-cols-3 w-1/2 my-3 items-center">
                        <h1 className="text-xs font-normal text-gray-500 col-span-1">
                          Reason
                        </h1>
                        <div className="flex  flex-col justify-left  w-full col-span-2">
                          <select
                            id="reason"
                            name="reason"
                            value={formik.values.reason}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.reason && formik.errors.reason
                                ? Style.iError
                                : Style.iInput
                            }
                          >
                            <option value="" disabled>
                              <h1 className="text-sm font-thin text-slate-800">
                                Select Reason
                              </h1>
                            </option>
                            {cancelReasons.map((v) => (
                              <option value={v["id"]}>
                                <h1 className="text-sm font-thin text-slate-800">
                                  {v["name"]}
                                </h1>
                              </option>
                            ))}
                          </select>
                          {formik.touched.reason && formik.errors.reason ? (
                            <div className="error">{formik.errors.reason}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="grid grid-cols-3 w-1/2 my-3 items-center">
                        <h1 className="text-xs font-normal text-gray-500 col-span-1">
                          Comments
                        </h1>
                        <div className="flex flex-col justify-left  w-full col-span-2">
                          <textarea
                            placeholder="Enter your Comments"
                            name="incomplete_comments"
                            id="incomplete_comments"
                            // value={formik.values.incomplete_comments}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.incomplete_comments &&
                              formik.errors.incomplete_comments
                                ? Style.iError
                                : Style.iInput
                            }
                            rows={3}
                            cols={40}
                          />
                          {formik.touched.reason && formik.errors.reason ? (
                            <div className="error">
                              {formik.errors.incomplete_comments}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="pt-10 flex justify-end">
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className={
                        !isDisabled
                          ? "flex items-center px-3 py-2 text-white text-sm font-semibold button-primary border rounded-md mb-3"
                          : "flex items-center px-3 py-2 text-white text-sm font-semibold button-primary-tint border rounded-md mb-3"
                      }
                    >
                      Submit
                    </button>
                  </div>
                </form>
                {scheduleDetail["status"] === "Completed" && (
                  <div className="flex justify-start mt-2">
                    <div className="my-2 flex flex-col gap-2">
                      {S.getFileExtension(scheduleDetail.video_url) != "mp4" ? (
                        <ReactHlsPlayer
                          src={scheduleDetail.video_url}
                          autoPlay={false}
                          controls={true}
                          width="750"
                          height="500"
                        />
                      ) : (
                        <video
                          src={scheduleDetail.video_url}
                          autoPlay={false}
                          controls={true}
                          width="750"
                          height="500"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-3/5 bg-white pl-5 pr-5 py-10">
                <div className="flex flex-row justify-center font-bold text-sm text-black">
                  Feedback has been submitted Already
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default InterviewerAddTechFeedback;
