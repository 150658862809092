import React from "react";
import {
  BsCameraVideo,
  BsUpload,
  BsFolderSymlink,
  BsUbuntu,
  BsClock,
} from "react-icons/bs";
import BarChart from "../../component/BarChart";
import orgApi from "../../api/org_api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import { CloseModal } from "../../component/Layout/CloseModal";
import { format } from "date-fns";
import { timezone } from "../../Services/Data";
import { BaseUrl } from "../../api/basr_url";
import S from "../../Services/Services";
import SwitchComp from "../../component/Switch";
import { DashCard } from "../../component/Dashboard/CustomComponent";
const emptyChart = require("../../assets/images/emptyChart.png");

type DashboardProps = { notify: boolean; setLoading: Function };
const Dashboard: React.FC<DashboardProps> = ({
  setLoading,
}: DashboardProps): JSX.Element => {
  const [dashboard, setDashboard] = React.useState([]);
  const [fileError, setfileError] = React.useState("");
  const [uploadResumeModal, setUploadResumeModal] = React.useState(false);
  const [selectedResume, setselectedResume] = React.useState<File[]>([]);
  const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
  const [live, setLive] = React.useState([]);
  const [chart, setChart] = React.useState([]);
  const [scheduleProfile, setScheduleProfile] = React.useState(0);
  const [profile, setProfile] = React.useState([]);
  const [subscription, setSubscription] = React.useState({} as any);
  const [resumeType, setResumeType] = React.useState([] as any);
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.setItem("jd_currentpage", "0");
    localStorage.setItem("jd_totalpage", "0");
    getDashboard();
    getLiveInterview();
    getSubscription();
  }, []);
  const getDashboard = async () => {
    setLoading(true);
    const res = await orgApi.dashSchedule(org.id);
    setDashboard(res.data.schedule);
    setLoading(false);
  };
  const getLiveInterview = async () => {
    setLoading(true);
    const res = await orgApi.dashLive(org.id);
    if (res.data.success) {
      setLive(res.data.live);
      setProfile(res.data.profile);

      const dates = [] as any;
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let currentDate = new Date();

      for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate);
        dates.push({
          date: format(date, "dd MMM yyyy"),
          day: days[date.getDay()],
          schedule: 0,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
      var p = 0;
      for (let index = 0; index < dates.length; index++) {
        var data1 = res.data.profile.filter((item: any) => {
          return format(item.date, "dd MMM yyyy") === dates[index]["date"];
        });
        if (data1.length !== 0) {
          dates[index]["schedule"] = Number(data1[0]["schedule"]);
          p = p + Number(data1[0]["schedule"]);
        }
      }
      setChart(dates);
      setScheduleProfile(p);
    }

    setLoading(false);
  };
  const getSubscription = async () => {
    setLoading(true);
    let userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    let orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    const res = await orgApi.getSubs(orgId);
    if (res.data.success) {
      setSubscription(res.data.org);
    }
    setLoading(false);
  };
  const openUploadResume = () => {
    setselectedResume([]);
    setUploadResumeModal(true);
  };
  const removeResume = async (index: number) => {
    setselectedResume((prevState) =>
      prevState.filter((_, ind) => ind !== index)
    );
    setResumeType((prevState: any) =>
      prevState.filter((_: any, ind: number) => ind !== index)
    );
  };
  const uploadResume = async () => {
    setUploadResumeModal(false);
    navigate("/job_description/", {
      state: { resumes: selectedResume, resumeType: resumeType },
    });
  };
  const handleResumeChange = async (event: any) => {
    const ds: any = await S.handleUploadResume(event.target.files);
    setfileError(ds["error"]);
    setselectedResume(ds["resumefiles"]);
    setResumeType(ds["resumeType"]);
  };
  const gotoInterviewResult = (x: any) => navigate("/interview_result/" + x);

  const getTz = (tz: any) => {
    var timezonetype = "";
    for (let i = 0; i < timezone.length; i++) {
      timezone[i].fullName === tz && (timezonetype = timezone[i].name);
    }
    return timezonetype;
  };

  const gotoSchedulePage = (date: any) =>
    navigate("/signle_date_schedule/" + date);

  const connect = (
    id: any,
    sid: any,
    job_title: any,
    schedule: any,
    jdDetail: any
  ) => {
    var userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    localStorage.setItem("organization", userInfo.org_name);
    localStorage.setItem("org_data", JSON.stringify(userInfo));
    localStorage.setItem("job_title", job_title);
    localStorage.setItem("jd", JSON.stringify(jdDetail));
    localStorage.setItem("schedule", JSON.stringify(schedule));
    localStorage.setItem("channel", id);
    localStorage.setItem("type", "1");
    localStorage.setItem("sid", sid);
    localStorage.setItem("take_ss", "flase");
    localStorage.setItem(
      "name",
      userInfo.first_name + " " + userInfo.last_name
    );
    localStorage.setItem("stType", "1");
    var str = BaseUrl.substr(BaseUrl.lastIndexOf("/") + 1);
    var url = BaseUrl.replace(new RegExp(str), "");
    window.open(url + "screenitMeeting/#/meeting");
    // window.open(url + "https://org.screenit.io/v2/screenitMeeting/#/meeting");
  };

  const greetings = () => {
    let myDate = new Date();
    let hours = myDate.getHours();
    var greet;

    if (hours < 12) greet = "morning";
    else if (hours >= 12 && hours <= 17) greet = "afternoon";
    else if (hours >= 17 && hours <= 24) greet = "evening";

    return `Good ${greet}`;
  };

  const CARD_DATA = [
    {
      id: "1",
      text: "Job Description",
      content: org?.no_of_post,
    },
    {
      id: "2",
      text: "Interview",
      content: org?.completed,
    },
    {
      id: "3",
      text: "No Shows",
      content: org?.no_show,
      onClick: () => gotoInterviewResult("no_show"),
    },
  ];

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>

      {uploadResumeModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Upload Resume
                    </h6>
                  </div>
                  <div className="mt-1">
                    <form className="max-w-lg mx-auto mt-4">
                      <div className="flex items-center w-full border-primary rounded ">
                        <div className="cursor-pointer w-full">
                          <label className="cursor-pointer w-full flex justify-start">
                            {selectedResume.length > 0 ? (
                              <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Change
                              </div>
                            ) : (
                              <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Upload
                              </div>
                            )}
                            <div className="flex justify-start items-center w-2/4 ">
                              &nbsp;
                              {selectedResume.length !== 0 && (
                                <div className="text-sm px-3 text-gray-400 truncate">
                                  {selectedResume[0]?.name}
                                </div>
                              )}
                              <input
                                type="file"
                                className="hidden"
                                multiple={true}
                                onChange={handleResumeChange}
                              ></input>
                            </div>
                          </label>
                        </div>
                      </div>
                      {fileError && (
                        <div className="error mb-2">{fileError}</div>
                      )}
                      <div
                        className="mt-1 text-xs text-gray-500"
                        id="user_avatar_help"
                      >
                        Supported format (.pdf, .docx), Size (Max 3MB)
                      </div>
                      {selectedResume.length > 0 && (
                        <>
                          {selectedResume.map((resume: any, index: number) => (
                            <>
                              <div
                                className="flex flex-row justify-between my-2"
                                key={index}
                              >
                                <div className="flex flex-row items-center gap-2 justify-start">
                                  <SwitchComp
                                    resumeType={resumeType}
                                    setResumeType={setResumeType}
                                    index={index}
                                    on={"Auto"}
                                    off={"Manual"}
                                  ></SwitchComp>
                                  <span className=" text-sm font-medium text-gray-600">
                                    {resume.name}
                                  </span>
                                </div>
                                <button
                                  onClick={() => removeResume(index)}
                                  type="button"
                                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  <CloseModal />
                                </button>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </form>
                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        className={
                          selectedResume.length > 0
                            ? " button-primary mt-2 py-1 px-2.5 text-white outline-none text-sm rounded-md"
                            : " bg-indigo-300 mt-2 py-1 px-2.5 text-white outline-none text-sm rounded-md"
                        }
                        onClick={() => uploadResume()}
                        disabled={selectedResume.length > 0 ? false : true}
                      >
                        Next
                      </button>
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                        onClick={() => setUploadResumeModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="max-h-full overflow-y-auto">
        <div className="px-4 grid grid-cols-4 grid-rows-5 gap-2 ">
          <div
            className="grid my-2 grid-cols-3 col-span-3 row-span-5 gap-2"
            style={{ height: "85vh" }}
          >
            <div className="row-span-1 " style={{ height: "8vh" }}>
              <div className="flex flex-col justify-items-start items-start gap-1">
                <span className="text-xl 2xl:text-2xl font-semibold text-black">
                  {greetings()}!
                </span>
                <span className="text-xs 2xl:text-base xl:text-sm font-normal text-gray-400">
                  You can access all the stuff as you wish.
                </span>
              </div>
            </div>
            <div
              className="col-span-2 row-span-1 bg-white "
              style={{ height: "8vh" }}
            >
              <div className="upload-buttons gap-2 ">
                <NavLink to="/upload_jd" state={{ postId: "" }}>
                  <button className="flex items-center px-3 py-1.5 text-white button-primary text-sm 2xl:text-base font-semibold border border-primary rounded-md mb-3">
                    <BsUbuntu size={20} />
                    &nbsp;&nbsp;Quick Create
                  </button>
                </NavLink>
                <NavLink to="/job_description">
                  <button className="flex items-center px-3 py-1.5 text-primary text-sm 2xl:text-base font-semibold border border-primary rounded-md mb-3 ml-2">
                    <BsFolderSymlink size={20} />
                    &nbsp;&nbsp;View JDs
                  </button>
                </NavLink>

                <div
                  onClick={() => openUploadResume()}
                  className="cursor-pointer"
                >
                  <button
                    disabled={org.no_of_post === 0}
                    className={
                      org.no_of_post === 0
                        ? "flex items-center px-3 py-1.5 text-gray-300 border-gray-300 text-sm 2xl:text-base font-semibold border rounded-md mb-3 ml-2"
                        : "flex items-center px-3 py-1.5 text-primary border-primary text-sm 2xl:text-base font-semibold border rounded-md mb-3 ml-2"
                    }
                  >
                    <BsUpload size={20} />
                    &nbsp;&nbsp;Upload Candidate
                  </button>
                </div>
              </div>
            </div>

            {CARD_DATA.map((card) => (
              <DashCard
                key={card.id}
                Text={card.text}
                Content={card.content}
                OnClick={card?.onClick}
              />
            ))}

            {/* <div
              className="row-span-1 flex justify-between px-5 bg-white border rounded-lg"
              style={{ height: "19vh" }}
            >
              <div
                className="flex flex-col justify-between py-4"
                style={{ height: "17vh" }}
              >
                <span className="text-sm 2xl:text-lg font-normal text-gray-400">
                  Interview
                </span>

                <span className="text-2xl font-bold mt-1">
                  {org.completed}
                </span>

                <span className="block text-xs 2xl:text-lg mt-5 font-medium text-green-500 flex items-center">
                  <div className="inline-flex flex-shrink-0 items-center justify-center h-4 w-4 text-green-800 bg-green-100 rounded-full mr-1">
                    <BsArrowUp />
                  </div>{" "}
                  10.2% Increase
                </span>
              </div>
              <div className="inline-flex flex-shrink-0 items-center justify-center  mr-3">
                <BsGraphUpArrow />
              </div>
            </div>
            <div
              className="row-span-1 flex justify-between px-5 bg-white border rounded-lg"
              style={{ height: "19vh" }}
            >
              <div
                className="flex flex-col flex-wrap content-between py-3"
                style={{ height: "17vh" }}
                onClick={() => gotoInterviewResult("no_show")}
              >
                <div>
                  <span className="block text-sm 2xl:text-lg font-normal text-gray-400">
                    No Shows
                  </span>
                </div>
                <div>
                  <span className="block text-2xl font-bold mt-1">
                    {org.no_show}
                  </span>
                </div>
                 <span className="block text-xs 2xl:text-lg mt-5 font-medium text-red-500 flex items-center">
                  <div className="inline-flex flex-shrink-0 items-center justify-center h-4 w-4 text-red-800 bg-red-100 rounded-full mr-1">
                    <BsArrowDown />
                  </div>
                  4.1% decrease
                </span> 
              </div>
              <div className="inline-flex flex-shrink-0 items-center justify-center  mr-3">
                <BsGraphDownArrow />
              </div>
            </div> */}

            {/* Live Interview */}
            <div
              className="row-span-3 bg-white border rounded-lg"
              style={{ height: "56vh" }}
            >
              <div className=" px-5 sidebar-bg shadow rounded-lg h-full">
                <div
                  className="py-6 flex flex-col justify-between"
                  style={{ height: "55vh" }}
                >
                  <div>
                    <span className="mb-4 flex-row items-center justify-start block text-md 2xl:text-xl font-bold text-white">
                      <div className="inline-flex flex-shrink-0 items-center justify-center h-6 w-6 2xl:h-8 2xl:w-8 p-1 text-red-500 bg-white rounded-full mr-1">
                        <BsCameraVideo />
                      </div>
                      Live Interview
                    </span>
                    <div className="overflow-y-auto" style={{ height: "43vh" }}>
                      {live.length === 0 ? (
                        <>
                          {[1, 2, 3].map(() => {
                            return (
                              <>
                                <li className="flex items-center border-b  px-4  py-1 animate-pulse">
                                  <div className="flex-grow flex items-center border-gray-100 dark:border-gray-400 text-sm text-gray-600 dark:text-gray-100 py-2">
                                    <div className="flex-grow justify-between items-center grid grid-cols-5">
                                      <div className="self-center col-span-3 truncate">
                                        <div className="font-medium text-xs 2xl:text-base text-gray-600 hover:text-gray-900 rounded-xl bg-gray-300 w-2/3 p-2 my-2">
                                          {"    "}
                                        </div>
                                        <div className="font-normal text-xs 2xl:text-base text-gray-300 hover:text-gray-300 rounded-xl bg-gray-300 w-2/3 p-1 my-2">
                                          {"    "}
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0 ml-2 mr-1 col-span-2">
                                        <div className="flex items-center flex-col font-normal text-xs 2xl:text-base text-primary cursor-pointer">
                                          <span className="rounded-xl bg-gray-300 w-2/3 p-2 my-1">
                                            {"    "}
                                          </span>
                                          <span className="rounded-xl bg-gray-300 w-2/3 p-1 my-1">
                                            {"    "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        live.length > 0 &&
                        live.map((e: any) => {
                          return (
                            <li className="flex items-center border-b px-1 py-1 ">
                              <div className="flex-grow flex items-center border-gray-100 dark:border-gray-400 text-sm text-gray-600 dark:text-gray-100 py-2">
                                <div className="flex-grow flex justify-between items-center">
                                  <div className="self-center">
                                    <div className="text-xs 2xl:text-base text-white font-semibold hover:text-gray-900">
                                      {e.candidate.first_name}{" "}
                                      {e.candidate.last_name}
                                    </div>
                                    <div className="font-normal text-xs 2xl:text-base text-gray-200 hover:text-gray-300">
                                      {e.post.job_title}
                                    </div>
                                    <div className="flex justify-start">
                                      {/* <div className="flex flex-row items-center gap-1  text-gray-600">
                                      <BsCalendar color="white"/> &nbsp;
                                      <div className="text-xs font-normal text-gray-200">
                                        {format(
                                          e.utc_starttime,
                                          "dd MMM yyyy"
                                        )}
                                      </div>
                                    </div> */}
                                      <div
                                        className="flex flex-row justify-end items-center gap-1 text-gray-200 text-xs 2xl:text-base"
                                      >
                                        <BsClock color="white" /> &nbsp;
                                        <div className="font-semibold text-gray-200">
                                          <>
                                            {format(e.utc_starttime, "hh:mm a")}{" "}
                                            to{" "}
                                            {format(e.utc_endtime, "hh:mm a")}{" "}
                                            {getTz(e.candidate_tz)}
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        connect(
                                          e.meeting_id,
                                          e.id,
                                          e.post.job_title,
                                          e,
                                          e.post
                                        );
                                      }}
                                      className="text-xs 2xl:text-base px-1 text-primary font-medium bg-white rounded-sm"
                                    >
                                      Connect
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      )}
                    </div>

                    {/* <div className="flex items-center -space-x-2">
                      <div
                        className="interview-circle"
                        style={{ backgroundImage: "url(" + bannerImage + ")" }}
                      ></div>
                      <div
                        className="interview-circle"
                        style={{ backgroundImage: "url(" + bannerImage + ")" }}
                      ></div>
                      <div
                        className="interview-circle"
                        style={{ backgroundImage: "url(" + bannerImage + ")" }}
                      ></div>
                      <div className="interview-circle-count bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
                        +15
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* Weekly Schedule */}
            <div
              className="col-span-2 row-span-3 bg-white border rounded-lg"
              style={{ height: "56vh" }}
            >
              <div
                className="flex items-center justify-between"
                style={{ height: "10vh" }}
              >
                <div className="px-6">
                  <div className="text-md 2xl:text-xl font-semibold">
                    Schedules
                  </div>
                  {/* <div className="text-xs font-medium text-gray-400">
                    (This {chatFilter})
                  </div> */}
                </div>
                <div className="px-6 flex items-center">
                  <div className="text-2xl font-bold">{scheduleProfile}</div>

                  {/* <DropDown
                    options={chartFilters}
                    menuSelection={dropMenuSelection}
                    icon={() => <BsThreeDots />}
                  /> */}
                </div>
              </div>
              <div className="p-4 flex-grow">
                <section className="bg-transparent flex flex-col gap-2 font-DM-Sans text-base 2xl:text-xl 2xl:font-bold">
                  <div className="text-medium-brown bg-white rounded-[10px] sm:rounded-[20px] px-2 py-1">
                    <div className="border-b-2 border-b-cream pb-6">
                      {profile.length > 0 ? (
                        <BarChart
                          chartData={chart}
                          schedulePage={gotoSchedulePage}
                        />
                      ) : (
                        <img
                          src={emptyChart}
                          className="h-full w-full object-cover px-8 py-5"
                          alt="empty-chart"
                        />
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          {/* Subscription */}
          <div className=" grid grid-cols-1 col-span-1 row-span-5 gap-1">
            <div
              className="border rounded-lg my-1 flex flex-col gap-2 items-start py-3 w-full bg-gradient-to-r from-blue-900 to-blue-500 "
              style={{ height: "29vh" }}
            >
              <p className="text-white text-md 2xl:text-xl font-semibold px-3 pb-2 border-b w-full">
                Subscription
              </p>
              <table className="table-fixed w-full">
                <thead className="text-xs">
                  <tr>
                    <th></th>
                    <th align="center">
                      <p className="p-2 text-gray-200 font-thin 2xl:text-base">
                        Internal
                      </p>
                    </th>
                    <th align="center">
                      <p className="p-2 text-gray-200 font-thin 2xl:text-base">
                        External
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-xs">
                  <tr>
                    <td>
                      <p className="px-2 py-1 text-gray-200 2xl:text-base">
                        Valid Upto
                      </p>
                    </td>
                    <td align="center">
                      <p className="px-2 py-1 text-gray-50 font-semibold 2xl:text-base">
                        {subscription && subscription.i_validity ? (
                          <>{format(subscription.i_validity, "dd/MM/yy")}</>
                        ) : (
                          <>-</>
                        )}
                      </p>
                    </td>
                    <td align="center">
                      <p className="px-2 py-1 text-gray-50 font-semibold 2xl:text-base">
                        {subscription && subscription.e_validity ? (
                          <>{format(subscription.e_validity, "dd/MM/yy")}</>
                        ) : (
                          <>-</>
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="px-2 py-1 text-gray-200 2xl:text-base">
                        Available
                      </p>
                    </td>
                    <td align="center">
                      <p className="px-2 py-1 text-gray-50 font-semibold 2xl:text-base">
                        {subscription && subscription.i_available ? (
                          <>{subscription.i_available}</>
                        ) : (
                          <>-</>
                        )}
                      </p>
                    </td>
                    <td align="center">
                      <p className="px-2 py-1 text-gray-50 font-semibold 2xl:text-base">
                        {subscription && subscription.e_available ? (
                          <>{subscription.e_available}</>
                        ) : (
                          <>-</>
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="px-2 py-1 text-gray-200 2xl:text-base">
                        Conducted
                      </p>
                    </td>
                    <td align="center">
                      <p className="px-2 py-1 text-gray-50 font-semibold 2xl:text-base">
                        {subscription && subscription.i_available ? (
                          <>
                            {subscription.i_subscribed -
                              subscription.i_available}
                            /{subscription.i_subscribed}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </p>
                    </td>
                    <td align="center">
                      <p className="px-2 py-1 text-gray-50 font-semibold 2xl:text-base">
                        {subscription && subscription.e_available ? (
                          <>
                            {subscription.e_subscribed -
                              subscription.e_available}
                            /{subscription.e_subscribed}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="border rounded-lg" style={{ height: "56vh" }}>
              <div className="flex flex-col justify-start">
                <div
                  className="flex items-center justify-between border-b border-gray-100"
                  style={{ height: "10vh" }}
                >
                  <div className="px-3 ">
                    <div className="text-md 2xl:text-xl font-semibold">
                      Interview Result
                    </div>
                  </div>
                  <div className="px-6 flex items-center">
                    <div
                      className="text-xs 2xl:text-lg font-bold text-primary cursor-pointer"
                      onClick={() => gotoInterviewResult("all")}
                    >
                      View All
                    </div>
                  </div>
                </div>
                <div className="overflow-y-auto" style={{ height: "46vh" }}>
                  {dashboard.length === 0 && (
                    <>
                      {[1, 2, 3, 4, 5, 6].map(() => {
                        return (
                          <>
                            <li className="flex items-center border-b  px-4  py-1 animate-pulse ">
                              <div className="flex-grow flex items-center border-gray-100 dark:border-gray-400 text-sm text-gray-600 dark:text-gray-100 py-2">
                                <div className="flex-grow flex justify-between items-center grid grid-cols-5">
                                  <div className="self-center col-span-3 truncate">
                                    <div className="font-medium text-xs 2xl:text-base text-gray-600 hover:text-gray-900 rounded-xl bg-gray-200 w-2/3 p-2 my-2">
                                      {"    "}
                                    </div>
                                    <div className="font-normal text-xs 2xl:text-base text-gray-300 hover:text-gray-300 rounded-xl bg-gray-200 w-2/3 p-1 my-2">
                                      {"    "}
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 ml-2 mr-1 col-span-2">
                                    <div className="flex items-center flex-col font-normal text-xs 2xl:text-base text-primary cursor-pointer">
                                      <span className="rounded-xl bg-gray-200 w-2/3 p-2 my-1">
                                        {"    "}
                                      </span>
                                      <span className="rounded-xl bg-gray-200 w-2/3 p-1 my-1">
                                        {"    "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </>
                  )}
                  <ul className="space-y-2">
                    {dashboard.length > 0 &&
                      dashboard.map((e: any) => {
                        return (
                          <li className="flex items-center  border-b  px-4  py-1 ">
                            <div className="flex-grow flex items-center border-gray-100 dark:border-gray-400 text-sm text-gray-600 dark:text-gray-100 py-2">
                              <div className="flex-grow flex justify-between items-center grid grid-cols-5">
                                <div className="self-center col-span-3 truncate">
                                  <div className="font-medium text-xs 2xl:text-base text-gray-600 hover:text-gray-900">
                                    {e.candidate.first_name}{" "}
                                    {e.candidate.last_name}
                                  </div>
                                  <div className="font-normal text-xs 2xl:text-base text-gray-300 hover:text-gray-300">
                                    {e.post.job_title}
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ml-2 mr-1 col-span-2">
                                  {S.getOrgDomain() !== "nam-it.com" ? (
                                    <div
                                      onClick={() => S.viewFeedback(e.id)}
                                      className="flex items-center flex-col font-normal text-xs 2xl:text-base text-primary cursor-pointer"
                                    >
                                      <span>SIT Score</span>
                                      <span>{e.feedback[0].sit_score}/10</span>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => S.viewFeedback(e.id)}
                                      className="flex items-center flex-col font-normal text-xs 2xl:text-base text-primary cursor-pointer"
                                    >
                                      <span>Feedback</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
