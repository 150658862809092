import axios from "./axios";
import axios1 from "./demo_axios";
const intApi = {
  signup: async (data: any) => {
    return await axios.post(`/candidate/signup`, data);
  },
  resetPassword: async (data: any) => {
    return await axios.post(`/candidate/reset_password`, data);
  },
  forgotPassword: async (data: any) => {
    return await axios.post(`/candidate/forgot_password`, data);
  },
  verifyOtp: async (data: any) => {
    return await axios.post(`/candidate/otp_verify`, data);
  },
  register: async (data: any) => {
    return await axios.post(`/candidate/register`, data);
  },
  uploadResume: async (candidateId: any, data: any, token: string) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(
      `/candidate/` + candidateId + `/resume`,
      data,
      axiosConfig
    );
  },
  uploadProfileVideo: async (candidateId: any, data: any,token:string) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(
      `/candidate/` + candidateId + `/intro`,
      data,
      axiosConfig
    );
  },
  onboard: async (candidateId: any, data: any, token : any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(
      `/candidate/` + candidateId + `/register_interviewer`,
      data,
      axiosConfig
    );
  },
  signin: async (data: any) => {
    return await axios.post(`/candidate/signin`, data);
  },
  getProfile: async (candidateId: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("int_token")!}`,
      },
    };
    return await axios.get(
      `/candidate/` + candidateId + `/profile`,
      axiosConfig
    );
  },
  skills: async (data: any, candidateId: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("int_token")!}`,
      },
    };
    return await axios.put(
      `/candidate/` + candidateId + `/skill`,
      data,
      axiosConfig
    );
  },
  allSkills: async (data: any) => {
    return await axios.post(`/org/skills`, data);
  },
  updateSkill: async (data: any, candidateId: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("int_token")!}`,
      },
    };
    return await axios.post(
      `/candidate/` + candidateId + `/skill`,
      data,
      axiosConfig
    );
  },
  deleteSkill: async (candidateSkillId: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("int_token")!}`,
      },
    };
    return await axios.delete(
      `/candidate/` + candidateSkillId + `/skill`,
      axiosConfig
    );
  },
  addFeedback: async (data: any, scheduleId: any) => {
    return await axios.post(`/interviewer/` + scheduleId + `/feedback`, data);
  },
  editFeedback: async (data: any, scheduleId: any) => {
    return await axios.put(`/interviewer/` + scheduleId + `/feedback`, data);
  },
  getFeedback: async (id: any) => {
    return await axios.get(`/interviewer/${id}/feedback`);
  },
  getTemplateData: async (id: any) => {
    return await axios.get(`/interviewer/${id}/template_skill`);
  },
  getChat: async (id: any) => {
    return await axios.get(`/interviewer/chat/${id}`);
  },
  updateProfile: async (data: any, userId: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("int_token")!}`,
      },
    };
    return await axios.put(`/candidate/${userId}/profile`, data, axiosConfig);
  },

  scheduleList: async (data: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("int_token")!}`,
      },
    };
    return await axios.post(`/candidate/schedule_list`, data, axiosConfig);
  },

  // demo api
  signup1: async (data: any) => {
    return await axios1.post(`/candidate/signup`, data);
  },
  verifyOtp1: async (data: any) => {
    return await axios1.post(`/candidate/otp_verify`, data);
  },
  register1: async (data: any) => {
    return await axios1.post(`/candidate/register`, data);
  },
  allSkills1: async (data: any) => {
    return await axios1.post(`/org/skills`, data);
  },
  uploadResume1: async (candidateId: any, data: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("int_token1")!}`,
      },
    };
    return await axios1.post(
      `/candidate/` + candidateId + `/resume`,
      data,
      axiosConfig
    );
  },
  uploadProfileVideo1: async (candidateId: any, data: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("int_token1")!}`,
      },
    };
    return await axios1.post(
      `/candidate/` + candidateId + `/intro`,
      data,
      axiosConfig
    );
  },
  onboard1: async (candidateId: any, data: any) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("int_token1")!}`,
      },
    };
    return await axios1.post(
      `/candidate/` + candidateId + `/register_interviewer`,
      data,
      axiosConfig
    );
  },
};

export default intApi;
