import React from "react";
import { useNavigate } from "react-router-dom";

// import { BsUpload } from "react-icons/bs";
type NavbarProps = {
  notificationOpen: Function;
};

const Navbar: React.FC<NavbarProps> = ({
  notificationOpen,
}: NavbarProps): JSX.Element => {
  const userInfo = JSON.parse(
    localStorage.getItem("user__details")?.toString()!
  );
  const navigate = useNavigate();
  const [notify, setNot] = React.useState(false);
  const setNotify = (data: boolean) => {
    setNot(data);
    notificationOpen(data);
  };

  const gotoProfile = () => {
    navigate('/org_profile');
  }

  return (
    <header className="flex items-center h-15 px-6 sm:px-10 bg-white border-b">
      <button className="block sm:hidden relative flex-shrink-0 p-2 mr-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 focus:bg-gray-100 focus:text-gray-800 rounded-full">
        <span className="sr-only">Menu</span>
        <svg
          aria-hidden="true"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div className="relative w-full max-w-md sm:-ml-2">
        <div className="flex flex-nowrap items-start gap-16  -mb-3">
          <span className="text-sm 2xl:text-2xl xl:text-lg font-semibold">
            {userInfo.org_name}
          </span>
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center ml-auto">
        <button onClick={() => {gotoProfile();}} className="inline-flex items-center mt-1 mb-1  p-1 hover:bg-gray-100 focus:bg-gray-100 rounded-lg">
          <div className="hidden md:flex md:flex-col md:items-end md:leading-tight">
            <span className="text-sm 2xl:text-xl xl:text-base font-semibold">
              {userInfo.first_name} {userInfo.last_name}
            </span>
          </div>
          <span className="h-10 w-10 2xl:h-14 2xl:w-14 ml-2 sm:ml-3 mr-2 bg-gray-100 rounded-full overflow-hidden">
            <img
              src={userInfo.profile_pic}
              alt="user_profile_photo"
              className="h-full w-full object-cover"
            ></img>
          </span>
        </button>
        <button
        hidden
          onClick={() => setNotify(!notify)}
          className="relative p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100 focus:text-gray-600 rounded-full"
        >
          <span className="sr-only">Notifications</span>
          <span className="absolute top-0 right-0 h-2 w-2 mt-1 mr-2 bg-red-500 rounded-full"></span>
          <span className="absolute top-0 right-0 h-2 w-2 mt-1 mr-2 bg-red-500 rounded-full animate-ping"></span>
          <svg
            aria-hidden="true"
            fill="#5051F9"
            viewBox="0 0 24 24"
            stroke="#5051F9"
            className="h-6 w-6 2xl:h-10 2xl:w-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Navbar;
