import { roundToNearestMinutes } from "date-fns";
import moment from "moment";
import { timezone } from "./Data";
import { feedbackUrl } from "../api/basr_url";

export default {
  getSkills: (skills: any) => {
    var skillsString = "";
    skillsString = Array.prototype.map
      .call(skills, function (item) {
        return item["skill"]["name"];
      })
      .join(",");
    return skillsString;
  },
  roundToNearest30Minutes: (date: any) => {
    const roundedDate = new Date(date);
    roundedDate.setMilliseconds(0);
    roundedDate.setSeconds(0);
    roundedDate.setMinutes(Math.floor(date.getMinutes() / 30) * 30); // Round to the nearest 30 minutes
    return roundedDate;
  },
  generateDisabledTimes: (date: Date | null): Date[] | undefined => {
    if (!date) {
      date = roundToNearestMinutes(new Date());
    }
    if (!date) {
      return [];
    }
    const currentDate = new Date();
    const currentDateTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0,
      0
    );
    const selectedDateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    );
    if (!date || selectedDateTime < currentDateTime) {
      return [];
    }    
    if (selectedDateTime > currentDateTime) {
      return undefined;
    }
    const currentHour = currentDate.getHours();
    const disabledTimes = [];
    for (let h = 0; h < currentHour; h++) {
      disabledTimes.push(new Date().setHours(h, 0, 0, 0));
      disabledTimes.push(new Date().setHours(h, -30, 0, 0));
      disabledTimes.push(new Date().setHours(h, 30, 0, 0));
      if(h === currentHour-1 &&  currentDate.getMinutes() > 30) {
        disabledTimes.push(new Date().setHours(h + 1, 0, 0, 0));
        disabledTimes.push(new Date().setHours(h, 30, 0, 0));
      }
    }
    return disabledTimes.map((time) => new Date(time));
  },
  viewFeedback: (scheduleId: any) => {
    if(getOrgDomain() === 'nam-it.com') {
      window.open(feedbackUrl + "/view_feedback_tech_namit/" + scheduleId, "_blank");
    } else {
      window.open(feedbackUrl + "/view_feedback_tech/" + scheduleId, "_blank");
    }
  },
  getDateTz: (date: any, tz: any) => {
    return moment(date).utc().tz(tz).format('DD MMM YYYY')
  },
  getTimeTz: (date: any, tz: any) => {
    return moment(date).utc().tz(tz).format('hh:mm a')
  },
  getEndTimeTz: (date: any, tz: any) => {
    return moment(date).utc().tz(tz).add(1, 'h').format('hh:mm a')
  },
  getSrtTz: (tz: any) => {
    var timezonetype
    for (let i = 0; i < timezone.length; i++) {
      if (timezone[i].fullName === tz) {
        timezonetype = timezone[i].name
      }
    }
    return timezonetype
  },
  getOrgDomain: () => {
    const userEmail = localStorage.getItem("user_email")?.toString()!;
    const splitDomain = userEmail.split('@')[1];
    return splitDomain;
  },
  handleUploadResume: async (files: any) => {
    let error = "";
    let resumefiles = [];
    let resumeType = [];
    for (let k = 0; k < files.length; k++) {
      const file = files[k];
      if (file) {
        if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type === "application/pdf"
        ) {
          if (file.size <= 3 * 1024 * 1024) {
            resumefiles.push(file);
            resumeType.push(true)
            error = "";
          } else {
            error = "Please upload the file with size <3 MB";
          }
        } else {
          error = "Please upload only pdf or docx format";
        }
      }
    }
    const da={error,resumefiles, resumeType}
    return da;
  },
  getFileExtension: (filename :  any) => {
    const index = filename.lastIndexOf('.');
    return index !== -1 ? filename.substring(index + 1) : '';
  }
};
function getOrgDomain() {
  const userEmail = localStorage.getItem("user_email")?.toString()!;
  const splitDomain = userEmail.split('@')[1];
  return splitDomain;
}

